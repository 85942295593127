import styled from '@emotion/styled';
import React, { Suspense, useEffect, useState } from 'react';
import { colors, fontFamilies } from '../../components/baseStyle/Variables';
import Button from '../../components/button/Button';
import LongLoader from '../../components/longLoader/LongLoader';
import WallyPreApproval from '../../components/wally_pre_approval';
import { HardDecline, SoftDecline } from '../../gql/graphql';
import { selectLeaseHelper } from '../../helpers';
import { useSessionProperies } from '../../hooks/dtrum';
import { useStoreActions, useStoreState } from '../../hooks/easyPeasyHooks';
import { usePage, useTrack } from '../../hooks/segment';
import { LongFormStates } from '../../state-machine/types/longFormMachine.types';
import { AppMode } from '../../stores/order.store';
import ErrorsFound from '../errorsFound/ErrorsFound';
import { usePageText } from './pageText';
import { Role } from '../../helpers/cookies/cookie.helper';
import { AppConfig } from '../../app.config';
import DotLoader from '../../components/dotLoader/DotLoader';

type PreApprovalTriggerProps = {
  target?: LongFormStates;
  hardDeclineError?: HardDecline;
  softDeclineError?: SoftDecline;
};

export interface PreApprovalProps {
  onApproval: any;
  onDeclineOrError: (triggerProps?: PreApprovalTriggerProps) => void;
  onCompleteAppLater: () => void;
}

const ApprovalContainer = styled.div`
  text-align: left;

  strong {
    font-family: ${fontFamilies.interSemiBold};
    font-weight: 900;
  }
`;

const Gradient = styled.div`
  width: 100%;
  max-width: 465px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0) 100%);
  margin-top: 1.5rem;
  height: 50px;
`;

const BottomContent = styled.div`
  margin-top: 3rem;
`;

const ButtonFaqWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & button {
    margin-bottom: 0.5rem;
  }

  p,
  a {
    font-size: 0.65rem;
  }
`;

const PaymentTermsDisclosure = styled.span`
  display: flex;
  width: 100%;
  font-family: ${fontFamilies.interRegular};
  font-size: 0.75rem;
  margin: 0;
  line-height: 1rem;
  color: ${colors.darkBlue};
`;

const CompleteAppLaterWrapper = styled.div`
  padding: 1rem;
`;

const Label = styled.div`
  font-size: 1rem;
  font-family: ${fontFamilies.interSemiBold};
  color: ${colors.koalafiBlue};
  cursor: pointer;
  text-align: center;
`;

const PreApproval: React.FC<PreApprovalProps> = ({ onApproval, onDeclineOrError, onCompleteAppLater }) => {
  const { wasCardAuthorized, authorizeCardResponse, error: generalError } = useStoreState((state) => state.applicationForm);
  const applicationId = useStoreState((state) => state.order.order?.application?.lease?.id);
  const { orderId, order } = useStoreState((state) => state.order);
  const dealerId = useStoreState((state) => state.order.order?.dealer.id);
  const { authorizeCard, setErrorGeneral } = useStoreActions((actions) => actions.applicationForm);
  const leaseOption = useStoreState((state) => selectLeaseHelper(state.order.order?.application?.lease?.options));
  const track = useTrack();
  const page = usePage();
  const dtrumProps = useSessionProperies();
  const [shouldRedirect, setShouldRedirect] = useState<boolean>();
  const { bodyText, disclosureText, buttonText } = usePageText();
  const { role } = useStoreState((state) => state.auth);

  const [isRedirecting, setIsRedirecting] = useState(false);

  useEffect(() => {
    page('Pre-Approval', { orderId, dealerId, approvalAmount: leaseOption?.approvedAmount });
    track('Lease Pre-approved', { approvalAmount: leaseOption?.approvedAmount });

    dtrumProps({
      appmode: AppMode.lease as string,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleButtonClick = () => {
    if (applicationId != null) {
      track('Pre-Approval Continue', {
        approvalAmount: leaseOption?.approvedAmount,
      });

      setErrorGeneral(null);

      authorizeCard({
        applicationId: applicationId,
      });
      setShouldRedirect(true);
    }
  };

  const handleCompleteAppLater = () => {
    track('Complete Application Later', { role });

    if (role === Role.Customer) {
      onCompleteAppLater();
    } else {
      setIsRedirecting(true);
      window.location.href = `${AppConfig.dealerPortalUrl}/DealerCabinet/ApplicationDetails?publicId=${order?.application?.lease?.publicId}`;
    }
  };

  useEffect(() => {
    if (shouldRedirect && (wasCardAuthorized || authorizeCardResponse.authorizeCardErrors.length > 0)) {
      if (authorizeCardResponse.authorizeCardErrors.length > 0) {
        const hardDeclineError = authorizeCardResponse.authorizeCardErrors.find((err) => err.__typename === 'HardDecline') as HardDecline;
        const softDeclineError = authorizeCardResponse.authorizeCardErrors.find((err) => err.__typename === 'SoftDecline') as SoftDecline;

        if (!!hardDeclineError) {
          track('Debit Card Error', { errType: 'HardDecline' });
          onDeclineOrError({ target: LongFormStates.Declined, hardDeclineError });
          return;
        } else if (!!softDeclineError) {
          track('Debit Card Error', { errType: 'SoftDecline', decisionCode: softDeclineError?.extensions?.decisionCode });
          onDeclineOrError({ target: LongFormStates.ReviewAndSubmit, softDeclineError });
          return;
        } else {
          setErrorGeneral(authorizeCardResponse.authorizeCardErrors[0]);
          return;
        }
      }

      onApproval(order);
    }
  }, [authorizeCardResponse.authorizeCardErrors, onApproval, onDeclineOrError, shouldRedirect, order, track, wasCardAuthorized, setErrorGeneral]);

  if (shouldRedirect && generalError) {
    track('Application Error', {
      errorType: 'generalError',
      error: generalError,
    });

    return (
      <ErrorsFound
        header="We're having some trouble"
        body="We were unable to process your application. Please resubmit below."
        buttonText="Resubmit application"
        buttonBehavior={() => {
          setShouldRedirect(false);
        }}
      />
    );
  }

  return shouldRedirect ? (
    <LongLoader message="We're reviewing your application" />
  ) : (
    <ApprovalContainer>
      <Suspense fallback={<LongLoader message="We're reviewing your application" />}>
        <WallyPreApproval financingAmount={leaseOption?.approvedAmount} />
        <Gradient />
        <BottomContent>
          <>
            <h6>
              <strong>Just one last step to finalize your approval!</strong>
            </h6>
            <h6 style={{ lineHeight: 1.5 }} data-testid="bodyText">
              {bodyText}
            </h6>
            <PaymentTermsDisclosure> {disclosureText}</PaymentTermsDisclosure>
          </>
          <ButtonFaqWrapper>
            <Button onClick={handleButtonClick}>{buttonText}</Button>
            <CompleteAppLaterWrapper onClick={handleCompleteAppLater}>
              {isRedirecting ? <DotLoader /> : <Label>Complete application later</Label>}
            </CompleteAppLaterWrapper>
          </ButtonFaqWrapper>
        </BottomContent>
      </Suspense>
    </ApprovalContainer>
  );
};

export default PreApproval;
