import React, { useEffect } from 'react';
import Message from '../../components/message/Message';
import { inIframe, modalResolver } from '../../helpers';
import { ExitCodes, ModalMessage, ModalMessageFailure } from '../../helpers/modal/modal.helper';
import { useStoreState } from '../../hooks/easyPeasyHooks';
import { usePage, useTrack } from '../../hooks/segment';
import { Role } from '../../helpers/cookies/cookie.helper';

type Props = {
  heading?: string;
  body?: string | JSX.Element;
  buttonText?: string;
  additionalBody?: string;
  exitCode?: ExitCodes; //optional but defaulted to 7
  exitReason?: string; //optional but defaults to internal service error
};

const InternalError: React.FC<Props> = ({ exitCode = 7, exitReason = 'internal service error', ...rest }: Props) => {
  const { orderId } = useStoreState((state) => state.order);
  const { role } = useStoreState((state) => state.auth);
  const domains = useStoreState((state) => state.order.order?.dealer.domains!);
  const track = useTrack();
  const page = usePage();

  useEffect(() => {
    page('Internal Error');
    // const payload: ModalMessage = {
    //   wcfSuccess: false,
    //   closeModal: false,
    //   payload: {
    //     reason: exitReason,
    //     reasonCode: exitCode, //default to 7 - generic modal close
    //     orderId: orderId,
    //   } as ModalMessageFailure,
    // };
    track('Closing modal at internal service error', { orderId });
    // modalResolver(payload, domains);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseModal = () => {
    const payload: ModalMessage = {
      wcfSuccess: false,
      closeModal: true,
      payload: {
        reason: 'modal closed',
        reasonCode: ExitCodes.generalCloseModal,
        orderId: orderId,
      } as ModalMessageFailure,
    };
    modalResolver(payload, domains);
  };
  return (
    <Message
      heading={rest.heading || "We're having some trouble"}
      body={rest.body || "We're unable to complete your request right now, but are working on resolving this as soon as possible. Please try again later."}
      additionalBody={rest.additionalBody || ''}
      buttonText={rest.buttonText || 'Exit'}
      buttonClick={handleCloseModal}
      shouldShowButton={inIframe() || role !== Role.Customer} // Do not show for full screen customer role
    />
  );
};

export default InternalError;
