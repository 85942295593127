import { gql } from '@apollo/client';
import { apolloMutation } from '../apollo';

export interface BankConnectionService {
  generateBankConnectorToken(orderId: String): Promise<object>;
}

const bankConnectionService: BankConnectionService = {
  async generateBankConnectorToken(orderId: String) {
    return apolloMutation(GENERATE_BANK_CONNECTOR_TOKEN, { input: { orderId: orderId } });
  },
};

export const GENERATE_BANK_CONNECTOR_TOKEN = gql`
  mutation generateBankConnectorToken($input: GenerateBankConnectorTokenInput!) {
    generateBankConnectorToken(input: $input) {
      linkToken
      linkTokenExpiration
      generateBankConnectorTokenErrors {
        __typename
        ... on NotFound {
          message
        }
        ... on FatalError {
          message
        }
        ... on BadRequest {
          message
        }
      }
    }
  }
`;

export default bankConnectionService;
