import { assign, createMachine } from 'xstate';
import { AppSteps, ChannelTypes, FormTypes, Order } from '../gql/graphql';
import { acceptedDomain } from '../helpers';
import { AppMode } from '../stores/order.store';
import CustomerLookupMachine from './customer-lookup';
import longFormApplicationMachine from './long-form-application-machine';
import EntryPointMachine from './entrypoint-machine';
import ExistingApplicationMachine from './existing-application';
import NewApplicationMachine from './new-application';
import OtbMachine from './otb';
import { EcommMachineEvents, EcommMachineMachineContext, EcommMachineMachineStates, EcommMachineStates } from './types/ecommMachine.types';
import VirtualCardMachine from './virtual-card';

// Not ideal this is multiple places.  Will be removed once no longer FF
const determinePlaidFF = (context: EcommMachineMachineContext) => {
  // Gitlab Main shutoff point
  const levelOne = context.plaidFF;
  // Dealer level enablement
  const levelTwo = context.order?.dealer.supportsExternalBankConnector;
  let levelThree = true;
  // Plaid A/B testing dependent on customer id
  // Can be switched on/off at gitlab feature flag level
  if (context.plaidAB) {
    // Feature flag enabled for customers with EVEN ids
    levelThree = context.order?.application?.customer?.id! % 2 === 0;
  }

  return !!levelOne && !!levelTwo && !!levelThree;
};

const ecommMachine = (
  orderID: string,
  orderDetails: Order,
  appMode: AppMode,
  ecommAppFlow: boolean,
  highestSupportedApplicationMilestone: number,
  plaidFF: boolean,
  plaidABTesting: boolean,
  reviewPage?: boolean,
) => {
  return createMachine<EcommMachineMachineContext, any, EcommMachineMachineStates>(
    {
      id: 'ECommerceMachine',
      initial: EcommMachineStates.EcommMachine,
      context: {
        orderId: orderID,
        order: orderDetails,
        customerLookupStartingPage: null,
        existingApplicationStartingPage: null,
        longFormApplicationStartingPage: null,
        appMode: appMode,
        entryPointPage: 'OTPVerification',
        ecommApprovalOnly: ecommAppFlow,
        highestSupportedApplicationMilestone: highestSupportedApplicationMilestone,
        reviewPage: reviewPage ?? false,
        plaidFF: plaidFF,
        plaidAB: plaidABTesting,
      },
      states: {
        [EcommMachineStates.EcommMachine]: {
          id: 'EcommMachine',
          initial: EcommMachineStates[EcommMachineStates.Loading],
          states: {
            [EcommMachineStates.Loading]: {
              entry: [],
              exit: [
                assign({
                  order: (_, { order }) => order,
                  appMode: (_, { appMode }) => appMode,
                  highestSupportedApplicationMilestone: (_, { highestSupportedApplicationMilestone }) => highestSupportedApplicationMilestone,
                }),
                'postLoadingDone',
              ],
              tags: 'Loading',
              on: {
                [EcommMachineEvents.APP_IN_PROGRESS]: [
                  // Necessary for Unified UI Milestone 2 to avoid chunk load error when resuming a draft app in "reviewAndSubmit".
                  // When resuming a draft app in "reviewAndSubmit" for Milestone 2, the user is immediately redirected to RAPID.
                  // If the corresponding loader page is not included in the entrypoint machine (and therefore the main chunk), it is
                  // instead fetched from a different chunk, but the redirect interrupts the chunk from loading and instead
                  // displays an error page briefly during the redirect.
                  {
                    target: '#ECommerceMachine.EntryPoint',
                    cond: 'EntryPointDraftAppReviewAndSubmit',
                    actions: assign({
                      entryPointPage: (_context) => 'DraftAppReviewAndSubmit',
                    }),
                  },
                  {
                    target: '#ECommerceMachine.LongFormApplication',
                    cond: 'LongFormDraftApp',
                  },
                  {
                    target: '#ECommerceMachine.LongFormApplication',
                    cond: 'EntryPointDraftAppReviewOnly',
                    actions: assign({
                      entryPointPage: (_context, _event) => 'DraftAppReviewAndSubmit',
                      reviewPage: (_context, _event) => _context.reviewPage,
                    }),
                  },
                  {
                    target: '#ECommerceMachine.EntryPoint',
                    cond: 'EntryPointApprovalConfirmation',
                    actions: assign({
                      entryPointPage: (_context) => 'ApprovalConfirmation',
                    }),
                  },
                  {
                    target: '#ECommerceMachine.EntryPoint',
                    cond: 'EntryPointContractSummary',
                    actions: assign({
                      entryPointPage: (_context) => 'ContractSummary',
                    }),
                  },
                  // This is temporary while plaid is still a ff.  Once A/B testing is done, will keep 1 guard
                  {
                    target: '#ECommerceMachine.ExistingApplication',
                    cond: 'LeaseBankAccountErrorFF',
                    actions: assign({
                      existingApplicationStartingPage: (_context, _event) => 'BankDetailsFF',
                    }),
                  },
                  {
                    target: '#ECommerceMachine.EntryPoint',
                    cond: 'LeaseBankAccountError',
                    actions: assign({
                      entryPointPage: (_context, _event) => 'BankAccountError',
                    }),
                  },
                  {
                    target: '#ECommerceMachine.EntryPoint',
                    cond: 'LeaseDebitCardError',
                    actions: assign({
                      entryPointPage: (_context, _event) => 'DebitCardError',
                    }),
                  },
                  {
                    target: '#ECommerceMachine.EntryPoint',
                    cond: 'LeasePreApproved',
                    actions: assign({
                      entryPointPage: (_context, _event) => 'PreApproval',
                    }),
                  },
                  {
                    target: '#ECommerceMachine.CustomerLookupFlow',
                    cond: 'ShortFormDraftApp',
                    actions: assign({
                      customerLookupStartingPage: (_context, _event) => 'BasicInfo',
                    }),
                  },
                  {
                    target: '#ECommerceMachine.EntryPoint',
                    actions: assign({
                      entryPointPage: (_context, _event) => 'WelcomeBack',
                    }),
                  },
                ],
                [EcommMachineEvents.ERRROR]: [{ actions: 'postLoadingDone' }],
                [EcommMachineEvents.APP_FINISHED]: [
                  {
                    target: '#ECommerceMachine.EntryPoint',
                    actions: assign({
                      entryPointPage: (_context, _event) => 'Done',
                    }),
                  },
                ],
                [EcommMachineEvents.CUSTOMER_LOOKUP_EVENT]: {
                  target: '#ECommerceMachine.EntryPoint',
                  actions: assign({
                    entryPointPage: (_context) => 'OTPVerification',
                  }),
                },
                [EcommMachineEvents.DECLINE]: {
                  target: '#ECommerceMachine.EntryPoint',
                  actions: assign({
                    entryPointPage: (context) => (context.appMode === AppMode.loan ? 'LoanDecline' : 'LeaseDecline'),
                  }),
                },
              },
              invoke: {
                src:
                  ({ orderId, order, appMode, highestSupportedApplicationMilestone, reviewPage }) =>
                  async (cb) => {
                    try {
                      if (!!order) {
                        // Leaving this here because without redux, this is where I'd want to fetch the order
                        // const response = await fetchOrder(orderId);
                        const getEventType = DetermineOrderPath(order, reviewPage);
                        // Again, leaving this here. Not necessary since we already have the order
                        // However, when we fetch the order from the machine will need to return this
                        cb({
                          type: getEventType,
                          order,
                          appMode,
                          highestSupportedApplicationMilestone,
                        });
                      } else {
                        throw Error('User Data is null');
                      }
                    } catch (e) {
                      console.log(e);
                      // cb({ type: UpdateEvents.ERROR });
                    }
                  },
              },
            },
          },
        },
        [EcommMachineStates.EntryPoint]: {
          invoke: {
            id: 'EntryPointMachine',
            src: EntryPointMachine,
            data: {
              page: (ctx: EcommMachineMachineContext) => ctx.entryPointPage,
              order: (ctx: EcommMachineMachineContext) => ctx.order,
              ecommApprovalOnly: ({ ecommApprovalOnly }: EcommMachineMachineContext) => ecommApprovalOnly,
            },
          },
          on: {
            [EcommMachineEvents.CUSTOMER_LOOKUP_EVENT]: {
              target: EcommMachineStates.CustomerLookupFlow,
              actions: assign({
                customerLookupStartingPage: (_context, _event) => 'OTPCheck',
              }),
            },
            [EcommMachineEvents.EXISTING_APPLICATION]: {
              target: EcommMachineStates.ExistingApplication,
              actions: assign({
                existingApplicationStartingPage: (_context, event) => {
                  switch (event?.data?.source) {
                    case 'WelcomeBack.NEXT':
                      return null;
                    case 'ContractSummary.NEXT':
                      return 'LeaseAgreement';
                    case 'ContractSummary.BACK':
                      return 'CartSummary';
                    case 'BankAccountError.NEXT':
                      return 'BankDetails';
                    case 'DebitCardError.NEXT':
                      return 'DebitCardEntry';
                    case 'ContractSummary.EDIT_DEBIT_INFO':
                      return 'DebitCardEntry';
                  }
                },
              }),
            },
            [EcommMachineEvents.LONG_FORM]: {
              target: EcommMachineStates.LongFormApplication,
              actions: assign({
                longFormApplicationStartingPage: (_context, event) => {
                  switch (event?.data?.source) {
                    case 'Approved':
                      return 'ApprovalConfirmation';
                    case 'DeclinedOrError':
                      return 'Declined';
                    case 'CompleteAppLater':
                      return 'CompleteAppLater';
                  }
                  if (_context.reviewPage) {
                    return 'ReviewAndSubmit';
                  }
                },
                reviewPage: (_context, event) => _context.reviewPage,
              }),
            },
          },
        },
        [EcommMachineStates.ExistingApplication]: {
          invoke: {
            id: 'ExistingApplicationMachine',
            src: ExistingApplicationMachine,
            data: {
              order: (ctx: EcommMachineMachineContext) => ctx.order,
              appMode: (ctx: EcommMachineMachineContext) => ctx.appMode,
              skipApproval: (_context: any, event: any) => !!event?.data?.skipApproval,
              skipToPage: (ctx: EcommMachineMachineContext) => ctx.existingApplicationStartingPage,
              ecommApprovalOnly: (ctx: EcommMachineMachineContext) => ctx.ecommApprovalOnly,
              plaidFF: (ctx: EcommMachineMachineContext) => ctx.plaidFF,
              plaidAB: (ctx: EcommMachineMachineContext) => ctx.plaidAB,
              orderId: (ctx: EcommMachineMachineContext) => ctx.orderId,
            },
          },
          on: {
            [EcommMachineEvents.VIRTUAL_CARD_APP]: {
              target: '#ECommerceMachine.VirtualCard',
            },
          },
        },
        [EcommMachineStates.CustomerLookupFlow]: {
          invoke: {
            id: 'CustomerLookupMachine',
            src: CustomerLookupMachine,
            data: {
              order: (ctx: EcommMachineMachineContext) => ctx.order,
              skipToPage: (ctx: EcommMachineMachineContext) => ctx.customerLookupStartingPage,
              ecommApprovalOnly: (ctx: EcommMachineMachineContext) => ctx.ecommApprovalOnly,
            },
          },
          on: {
            [EcommMachineEvents.NEW_APPLICATION]: {
              target: '#ECommerceMachine.NewApplication',
            },
            [EcommMachineEvents.OTB_CUSTOMER]: {
              actions: assign({
                order: (_context, event) => event.data.order,
              }),
              target: '#ECommerceMachine.OTB',
            },
            [EcommMachineEvents.LONG_FORM]: {
              actions: assign({
                order: (_context, event) => event.data.order,
                highestSupportedApplicationMilestone: (_context, event) => event.data.highestSupportedApplicationMilestone,
              }),
              target: EcommMachineStates.LongFormApplication,
            },
            [EcommMachineEvents.EXISTING_APPLICATION]: [
              {
                actions: assign({
                  order: (_context, event) => event?.data?.order,
                  appMode: (_context, event) => event?.data?.appMode,
                }),
                target: '#ECommerceMachine.ExistingApplication',
                cond: 'VirtualCardDealer',
              },
              {
                actions: assign({
                  order: (_context, event) => event?.data?.order,
                  appMode: (_context, event) => event?.data?.appMode,
                }),
                target: '#ECommerceMachine.ExistingApplication',
                cond: 'LeaseDebitCardError',
              },
              {
                actions: assign({
                  order: (_context, event) => event?.data?.order,
                  appMode: (_context, event) => event.data.appMode,
                  existingApplicationStartingPage: 'WelcomeBack',
                }),
                target: '#ECommerceMachine.ExistingApplication',
              },
            ],
          },
        },
        [EcommMachineStates.VirtualCard]: {
          invoke: {
            id: 'VirtualCardMachine',
            src: VirtualCardMachine,
            data: {
              order: orderDetails,
            },
          },
        },
        [EcommMachineStates.OTB]: {
          invoke: {
            id: 'OtbMachine',
            src: OtbMachine,
            data: {
              order: (ctx: EcommMachineMachineContext, _event: any) => ctx.order,
              customerFlowType: (_ctx: EcommMachineMachineContext, event: any) => event.data.customerFlowType,
              ecommApprovalOnly: (ctx: EcommMachineMachineContext) => ctx.ecommApprovalOnly,
              highestSupportedApplicationMilestone: (ctx: EcommMachineMachineContext) => ctx.highestSupportedApplicationMilestone,
            },
          },
          on: {
            [EcommMachineEvents.VIRTUAL_CARD_APP]: {
              target: '#ECommerceMachine.VirtualCard',
            },
            [EcommMachineEvents.EXISTING_APPLICATION]: {
              target: '#ECommerceMachine.ExistingApplication',
              actions: assign({
                order: (_context, event) => event?.data?.order,
                appMode: (_context, event) => event?.data?.appMode,
              }),
            },
            [EcommMachineEvents.NEW_APPLICATION]: [
              { target: '#ECommerceMachine.LongFormApplication', cond: 'IsLongForm' },
              { target: '#ECommerceMachine.NewApplication' },
            ],
          },
        },
        [EcommMachineStates.NewApplication]: {
          invoke: {
            id: 'NewApplicationMachine',
            src: NewApplicationMachine,
            data: (ctx: any) => ctx,
          },
          on: {
            [EcommMachineEvents.BACK]: {
              target: EcommMachineStates.CustomerLookupFlow,
              actions: assign({
                customerLookupStartingPage: (_context, _event) => 'BasicInfoContinued',
              }),
            },
            [EcommMachineEvents.EXISTING_APPLICATION]: {
              target: '#ECommerceMachine.ExistingApplication',
              actions: [
                assign({
                  order: (_context, event) => event?.data?.order,
                  appMode: (_context, event) => event.data.appMode,
                }),
              ],
            },
            [EcommMachineEvents.VIRTUAL_CARD_APP]: {
              target: '#ECommerceMachine.VirtualCard',
            },
            [EcommMachineEvents.DECLINE]: {
              target: '#ECommerceMachine.Declined',
            },
          },
        },
        [EcommMachineStates.Declined]: {
          entry: [
            assign({
              appMode: (_, event) => event?.data?.appMode,
            }),
          ],
          always: [
            {
              target: '#ECommerceMachine.LoanDeclined',
              cond: 'LoanApp',
            },
            {
              target: '#ECommerceMachine.LeaseDeclined',
            },
          ],
        },
        [EcommMachineStates.LoanDeclined]: {},
        [EcommMachineStates.LeaseDeclined]: {},
        [EcommMachineStates.LongFormApplication]: {
          invoke: {
            id: 'LongFormApplicationMachine',
            src: longFormApplicationMachine,
            data: {
              order: (ctx: EcommMachineMachineContext) => ctx.order,
              ecommApprovalOnly: (ctx: EcommMachineMachineContext) => ctx.ecommApprovalOnly,
              highestSupportedApplicationMilestone: (ctx: EcommMachineMachineContext) => ctx.highestSupportedApplicationMilestone,
              skipToPage: (ctx: EcommMachineMachineContext) => ctx.longFormApplicationStartingPage,
              isReadOnly: (ctx: EcommMachineMachineContext) => ctx.reviewPage,
              plaidFF: (ctx: EcommMachineMachineContext) => ctx.plaidFF,
              orderId: (ctx: EcommMachineMachineContext) => ctx.orderId,
            },
          },
          on: {
            [EcommMachineEvents.OTB_CUSTOMER]: {
              target: '#ECommerceMachine.OTB',
              actions: [
                assign({
                  order: (_context, event) => event?.data?.order,
                }),
              ],
            },
          },
        },
      },
      schema: {
        context: {} as EcommMachineMachineContext,
      },
      predictableActionArguments: true,
      preserveActionOrder: true,
    },
    {
      actions: {
        postLoadingDone: (context, _event) => {
          window.parent.postMessage(JSON.stringify({ stopSpinner: true }), acceptedDomain(context.order?.dealer?.domains!)!);
        },
        // strictly for debugging, do not remove and make sure you clean up references in MRs
        log: (context, event) => {
          console.log('Main Ecomm Machine');
          console.log('context', context);
          console.log('event', event);
        },
      },
      guards: {
        IsLongForm: (context, event) => context.order?.dealer?.formType === FormTypes.Long || context.order?.dealer?.formType === FormTypes.LongV2,
        LongFormDraftApp: (context) =>
          (context?.order?.application?.lease?.status === 'draft' || context?.order?.application?.lease?.status === 'errorsFound') &&
          (context?.order?.dealer?.formType === FormTypes.Long || context?.order?.dealer?.formType === FormTypes.LongV2),
        ShortFormDraftApp: (context) => context?.order?.application?.lease?.status === 'draft' && context?.order?.dealer?.formType === FormTypes.Short,
        LoanApp: (context) => context.appMode === AppMode.loan,
        VirtualCardDealer: (context, _event) => !!context.order?.dealer?.isVirtualCard,
        LeaseBankAccountError: (context, event) =>
          event?.data?.order?.application?.lease?.declineReason === 'bankAccount' || context?.order?.application?.lease?.declineReason === 'bankAccount',
        LeaseBankAccountErrorFF: (context, event) =>
          (event?.data?.order?.application?.lease?.declineReason === 'bankAccount' || context?.order?.application?.lease?.declineReason === 'bankAccount') &&
          context?.order?.dealer?.formType !== FormTypes.Long &&
          context?.order?.dealer?.formType !== FormTypes.LongV2 &&
          determinePlaidFF(context),
        LeaseDebitCardError: (context, event) =>
          event?.data?.order?.application?.lease?.declineReason === 'debitCardError' || context?.order?.application?.lease?.declineReason === 'debitCardError',
        LeasePreApproved: (context, event) =>
          event?.order?.application?.lease?.status === 'preApproved' && context?.order?.dealer?.formType !== FormTypes.Short,
        EntryPointApprovalConfirmation: (context, event) =>
          (context?.order?.application?.lease?.status === 'approved' || context?.order?.application?.lease?.status === 'docsReady') &&
          (context?.order?.dealer?.channelType === ChannelTypes.BrickAndMortar || !!context?.ecommApprovalOnly),
        EntryPointContractSummary: (context, event) => {
          // Should not skip if OTB (unless OTB + VC)
          if ((!!context?.order?.application?.lease?.isOtb && !context?.order?.dealer?.isVirtualCard) || !!context?.ecommApprovalOnly) {
            return false;
          } else {
            return context?.order?.application?.lease?.status === 'docsReady' || context?.order?.application?.lease?.status === 'approved';
          }
        },
        EntryPointDraftAppReviewAndSubmit: (context, event) =>
          context.highestSupportedApplicationMilestone === 2 && context.order?.application?.lease?.appStep === AppSteps.ReviewAndSubmit,
        EntryPointDraftAppReviewOnly: (context, event) => context.reviewPage,
      },
    },
  );
};

const DetermineOrderPath = (order: Order, reviewPage?: boolean): EcommMachineEvents => {
  const leaseAppId = order?.application?.lease?.id;
  const leaseAppStatus = order?.application?.lease?.status;
  const loanAppId = order?.application?.loan?.id;
  const loanAppStatus = order?.application?.loan?.status;
  const declineReason = order?.application?.lease?.declineReason;
  const isVirtualCard = order?.dealer?.isVirtualCard;
  const isShortForm = order?.dealer?.formType === FormTypes.Short;
  // const errors = order?.error;
  if (reviewPage) {
    return EcommMachineEvents.APP_IN_PROGRESS;
  }
  if (leaseAppId && leaseAppStatus) {
    // Need to use OTB, VirtualCard, ExistingApplication or New Application....
    switch (leaseAppStatus) {
      case 'readyForFunding':
      case 'approvedForFunding':
      case 'funded':
      case 'docsSigned':
        return isVirtualCard ? EcommMachineEvents.APP_IN_PROGRESS : EcommMachineEvents.APP_FINISHED;
      case 'docsReady':
      case 'approved':
      case 'preApproved':
        return EcommMachineEvents.APP_IN_PROGRESS;
      case 'errorsFound':
        if (!isShortForm || ['creditCardNumber', 'creditCardExpiration', 'debitCardError', 'bankAccount'].includes(declineReason!)) {
          return EcommMachineEvents.APP_IN_PROGRESS;
        } else if (declineReason === 'address' || declineReason === 'ssn') {
          return EcommMachineEvents.CUSTOMER_LOOKUP_EVENT;
        }
        return EcommMachineEvents.ERRROR;
      case 'draft':
        return EcommMachineEvents.APP_IN_PROGRESS;
      case 'declined':
        return EcommMachineEvents.DECLINE;
      default:
        return EcommMachineEvents.ERRROR;
    }
  } else if (loanAppId && loanAppStatus) {
    switch (loanAppStatus) {
      case 'approved':
      case 'docs_ready':
      case 'accepted':
      case 'delivered':
        return EcommMachineEvents.APP_IN_PROGRESS;
      case 'errors_found':
      case 'draft':
        return EcommMachineEvents.CUSTOMER_LOOKUP_EVENT;
      case 'declined':
        return EcommMachineEvents.DECLINE;
      default:
        return EcommMachineEvents.ERRROR;
    }
  }
  return EcommMachineEvents.CUSTOMER_LOOKUP_EVENT;
};
export default ecommMachine;
