import React from 'react';
import styled from '@emotion/styled';
import { colors, fontFamilies } from '../../components/baseStyle/Variables';
import Link from '../../components/Link';
import { Checkbox } from '../../components/formElements/FormElements';

type Props = {
  setChecked: (checked: boolean) => void;
  checked: boolean;
};

const AgreementDocs = styled.span`
  display: inline-block;
  margin-top: 0px !important;
  & a {
    text-decoration: none;
    cursor: pointer;
    font-family: ${fontFamilies.interLight};
    font-size: 1rem !important;
    color: ${colors.koalafiBlue};
  }
`;

const CheckboxWrapper = styled.div`
  cursor: pointer;
  display: flex;
  line-height: 0px !important;
  & input {
    margin-bottom: 2.4rem;
    font-size: 1rem;
  }
`;

const CheckboxLabel = styled.div`
  margin-left: 0.25rem;
  font-size: 0.8rem !important;
  line-height: 125% !important;
  font-family: ${fontFamilies.interLight};
  color: ${colors.darkBlue};
`;

const TermsAndConditions: React.FC<Props> = ({ setChecked, checked }) => {
  return (
    <div>
      <CheckboxWrapper onClick={() => setChecked(true)}>
        <Checkbox type="checkbox" name="acknowledgement" data-testid="terms-and-conditions-checkbox" checked={checked} onChange={() => {}} size={1} />

        <div>
          <CheckboxLabel>
            I understand and agree to the language contained in
            <AgreementDocs>
              <Link onClick={() => window.open('https://apply.koalafi.com/electronic-communications?support=lto')} linkText="Koalafi's E-Sign Agreement" />
              {', '}
              <Link onClick={() => window.open('https://koalafi.com/privacy-policy')} linkText="Privacy Policy" />
              {', '}
              <Link onClick={() => window.open('https://apply.koalafi.com/customer-id-program')} linkText="Customer Identification Program" />
              {', and '}
              <Link onClick={() => window.open('https://apply.koalafi.com/terms-and-conditions')} linkText="Koalafi Terms & Conditions" />
            </AgreementDocs>
          </CheckboxLabel>
        </div>
      </CheckboxWrapper>
    </div>
  );
};

export default TermsAndConditions;
