import React, { useEffect } from 'react';
import { inIframe, modalResolver } from '../../helpers';
import { ExitCodes, modalLoaderHelper, ModalMessage, ModalMessageFailure } from '../../helpers/modal/modal.helper';
import { useStoreState } from '../../hooks/easyPeasyHooks';
import { usePage, useTrack } from '../../hooks/segment';
import Header from '../../components/header/Header';
import styled from '@emotion/styled';
import { colors, fontFamilies } from '../../components/baseStyle/Variables';
import Button from '../../components/button/Button';
import { currentDomain } from '../../helpers/window/window.helpers';

const ErrorWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: ${fontFamilies.gilroyRegular};
  justify-content: center;
  margin: 0 auto;
  padding: 0 2rem 2rem;
`;

const Container = styled.div`
  text-align: left;
  position: relative;
`;

const HeadingWrap = styled.div`
  margin-bottom: 1rem;
`;

// TODO Move the button style to Chimera
const ButtonWrap = styled.div`
  margin: 50px 0;

  & button {
    font-family: ${fontFamilies.gilroySemiBold};
    font-size: 1.3rem;
    font-weight: normal;
    margin-top: 0.5rem;
    height: 58px;
  }
`;

const NotAllowedToCreateNewApplications: React.FC = () => {
  const { orderId } = useStoreState((state) => state.order);
  const domains = useStoreState((state) => state.order.order?.dealer.domains!);
  const storeName = useStoreState((state) => state.order.order?.dealer?.storeName);
  const track = useTrack();
  const page = usePage();

  useEffect(() => {
    page('Not Allowed To Create New Applications');
    track('Not Allowed To Create New Applications', { orderId });

    const sendDomain = domains ? domains : [currentDomain()];
    modalLoaderHelper({ stopSpinner: true }, sendDomain);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseModal = () => {
    const payload: ModalMessage = {
      wcfSuccess: false,
      closeModal: true,
      payload: {
        reason: 'modal closed',
        reasonCode: ExitCodes.generalCloseModal,
        orderId: orderId,
      } as ModalMessageFailure,
    };
    modalResolver(payload, domains);
  };
  return (
    <>
      <Header goBackFunc={undefined} currentNode={['NotAllowedToCreateNewApplications']} />
      <ErrorWrapper id="NotAllowedToCreateNewApplications">
        <Container>
          <HeadingWrap>
            <h2>We're sorry</h2>
          </HeadingWrap>
          <p data-testid="message-body" style={{ color: colors.darkBlue, fontSize: '1.1rem', lineHeight: '24px' }}>
            We are currently not accepting applications for financing at {storeName}.
          </p>
          <p style={{ color: colors.darkBlue, fontSize: '1.1rem', lineHeight: '24px' }}>
            If you need assistance, reach out to our support team at <a href="tel:844-937-8275">844-937-8275</a> for help.
          </p>
          {inIframe() && (
            <ButtonWrap>
              <Button onClick={handleCloseModal}>Exit</Button>
            </ButtonWrap>
          )}
        </Container>
      </ErrorWrapper>
    </>
  );
};

export default NotAllowedToCreateNewApplications;
