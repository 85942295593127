import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from '../../hooks/easyPeasyHooks';
import styled from '@emotion/styled';
import { ReactComponent as BankImage } from '../../assets/summary_icon_bank.svg';
import { ReactComponent as CardImage } from '../../assets/summary_icon_credit_card.svg';
import { useTrack } from '../../hooks/segment';
import Link from '../Link';
import { colors, fontFamilies } from '../baseStyle/Variables';

const PaymentMethodStyle = styled.div`
  color: ${colors.darkBlue};
`;
const ImageStyle = styled.div`
  width: 2em;
  margin-right: 10px;
  margin-bottom: 5px;
  max-width: 23px;
`;

const PaymentHeader = styled.div`
  font-family: ${fontFamilies.gilroyBold};
  font-size: 1.2rem;
  margin: 1rem 0;
`;

const RowWrapper = styled.div`
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 0.5px solid ${colors.darkBlue};
  border-top: 0.5px solid ${colors.darkBlue};
  margin: 0.5rem 0;
`;

const Label = styled.div`
  font-size: 0.9rem;
  width: 40%;
`;

const Value = styled.div`
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
  margin-left: auto;
  font-family: ${fontFamilies.interBold};
  width: 60%;
  display: flex;
  text-align: right;
  div:last-child {
    // margin-left: auto;
    // width: 70%;
    display: flex;
  }
`;

const StyledLink = styled.div`
  .a {
    margin-left: auto;
    font-size: 0.9rem;
    margin-top: -5px;
  }
`;

type Props = {
  prom?: ((...args: any[]) => Promise<void>) | undefined;
  onDebitEdit?: () => void;
};

const PaymentMethod: React.FC<Props> = ({ prom, onDebitEdit }) => {
  const { basicInfo } = useStoreState((state) => state.applicationForm);
  const { order } = useStoreState((state) => state.order);
  const { bankName } = useStoreState((state) => state.loan);
  const { getBankName } = useStoreActions((state) => state.loan);
  const [internalBankName, setInternalBankName] = useState<string>('');
  const routingNumber = order?.application?.lease?.bank?.routingNumber;
  const accountNumberLastFour = order?.application?.lease?.bank?.accountNumberLast4;
  const cardType = order?.application?.lease?.card?.provider.toUpperCase();
  const paymentMethod = accountNumberLastFour ? 'ACH' : 'DEBIT';
  const lastFour = paymentMethod === 'ACH' ? accountNumberLastFour : order?.application?.lease?.card?.lastFour;
  const track = useTrack();

  useEffect(() => {
    if (paymentMethod === 'ACH') {
      // get bank name & set internal reference
      getBankName(routingNumber!);
      setInternalBankName(bankName ? bankName.toString() : '');
    } else {
      setInternalBankName('');
    }
    track('OTB LTO Method of Payment', { paymentMethod: paymentMethod });
  }, [routingNumber, bankName]); // eslint-disable-line react-hooks/exhaustive-deps

  const PaymentImgComponent = () => {
    if (paymentMethod === 'ACH') {
      return (
        <ImageStyle>
          <BankImage title="Payment Icon" />
        </ImageStyle>
      );
    } else {
      return (
        <ImageStyle>
          <CardImage title="Payment Icon" />
        </ImageStyle>
      );
    }
  };

  const ACHComponent = () => {
    return (
      <div>
        {/* <BankNameStyle></BankNameStyle> */}
        <PaymentImgComponent />
        {internalBankName} ...{lastFour}
      </div>
    );
  };

  const DebitComponent = () => {
    if (cardType === '') {
      return <div>Card ending in ${lastFour}</div>;
    } else {
      return (
        <div>
          <PaymentImgComponent />
          {cardType}...{lastFour}
        </div>
      );
    }
  };

  return (
    <PaymentMethodStyle>
      <PaymentHeader style={{ marginTop: -8 }}>Method of Payment</PaymentHeader>
      <RowWrapper>
        <Label>
          <strong>Recurring payments details</strong>
        </Label>
        <Value>
          <div>
            {paymentMethod === 'DEBIT' && <DebitComponent />}
            {paymentMethod === 'ACH' && <ACHComponent />}
          </div>
        </Value>
        {paymentMethod === 'DEBIT' && (
          <StyledLink>
            <Link
              onClick={() => {
                track('OTB Edit Debit Card Selected');
                if (!!onDebitEdit) {
                  onDebitEdit();
                }
              }}
              linkText="Edit"
            />
          </StyledLink>
        )}
      </RowWrapper>
      {paymentMethod === 'ACH' && (
        <p>
          The customer,{' '}
          <strong>
            {basicInfo.firstName?.toUpperCase()} {basicInfo.lastName?.toUpperCase()}
          </strong>
          , authorizes Koalafi to validate and debit payments and fees from the provided bank account. The customer acknowledges that if approved, payment
          rights and obligations will be described in the Payment Method section in their lease agreement.
        </p>
      )}
    </PaymentMethodStyle>
  );
};

export default PaymentMethod;
