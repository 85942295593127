import { Option } from '../../gql/graphql';

const selectLoanHelper = (loanOptions: Option[] | undefined) => {
  if (!loanOptions || loanOptions.length === 0) return null;

  return loanOptions.length === 1
    ? // if only one option, use that
      loanOptions[0]
    : // if more than one option, get the option with the greatest deferredInterestPeriod
      loanOptions.reduce((prevVal: any, currVal) => (currVal.deferredInterestPeriod > (prevVal?.deferredInterestPeriod || -1) ? currVal : prevVal), {});
};

const selectLoanHelperOTB = (loanOptions: Option[] | null | undefined) => {
  if (!loanOptions || loanOptions.length === 0) return null;

  return loanOptions.length === 1
    ? // if only one option, use that
      loanOptions[0]
    : // if more than one option, get the option with the greatest lineAmount
      loanOptions.reduce((prevVal: any, currVal) => (currVal.lineAmount > (prevVal?.lineAmount || -1) ? currVal : prevVal), {});
};

const greatestLineAmountHelper = (loanOptions: Option[] | null | undefined, cartTotal: number) => {
  if (!loanOptions || loanOptions.length === 0) {
    return null;
  }

  var loanOptionsWithinMinimumSpend = loanOptions.filter((leaseOption) => cartTotal === 0 || Number(leaseOption.minimumSpend) <= cartTotal);

  if (loanOptionsWithinMinimumSpend.length === 0) {
    return null;
  }

  return Math.max(...loanOptionsWithinMinimumSpend.map((loanOption) => loanOption.lineAmount));
};

const getLowestMinimumSpend = (loanOptions: Option[] | null | undefined) => {
  if (!loanOptions || loanOptions.length === 0) return null;

  return loanOptions.length === 1 ? loanOptions[0].minimumSpend : Math.min(...loanOptions.map((loanOption) => loanOption.minimumSpend));
};

export { selectLoanHelper, selectLoanHelperOTB, greatestLineAmountHelper, getLowestMinimumSpend };
