import applicationFormService from '../../services/applicationForm.service';
import { BankConnectionDetails, BankConnectionTypes, UpdateBankAccountInput } from '../../gql/graphql';
import { error } from 'xstate/lib/actions';

const updateBankAccount = async (appId: number, plaidPublicToken: string, plaidLinkToken: string) => {
  const connectDetails: BankConnectionDetails = {
    bankConnectionType: BankConnectionTypes.Plaid,
    bankConnectionToken: plaidPublicToken,
    bankConnectionCreationToken: plaidLinkToken,
  };
  const updateBankAccountPayload: UpdateBankAccountInput = {
    applicationId: appId,
    bankConnectionDetails: connectDetails,
  };

  const { data, errors } = (await applicationFormService.updateBankAccount(updateBankAccountPayload)) as any;
  if (errors) {
    throw error('unable to update bank account', errors);
  }
  return data.updateBankAccount;
};

export { updateBankAccount };
