import React from 'react';
import styled from '@emotion/styled';
import { colors, fontFamilies } from '../baseStyle/Variables';
import { Popover, PopoverTrigger, PopoverContent, PopoverDescription } from '../popover/popover';

type LeaseTermsTooltipProps = {
  styling?: any;
  shortened?: boolean;
};

const DetailText = styled.div`
  text-align: left;
  font-size: 0.8rem;
  max-width: 250px;
  color: ${colors.almostBlack};
  background-color: ${colors.white} !important;
  font-family: ${fontFamilies.interLight};
  padding: 0.8rem !important;
  border: none;
  box-shadow: 0px 2px 6px ${colors.darkGray} !important;
}
`;

const fulllText = (
  <DetailText>
    Consumer leasing is a financing option in which the provider will purchase merchandise from a merchant and lease it to you. Lease-to-own (or rent-to-own)
    financing are not loans. For example, instead of paying interest, you will pay rental fees on top of the retail price. You will not own the property until
    you complete all payments or take advantage of an Early Purchase Option under the full term of the agreement. The minimum lease term may vary with a maximum
    lease term of 48 months. Please review your lease agreement for complete terms and conditions. Eligibility for lease-to-own financing may vary according to
    state law. In the event you reside in a state that does not allow lease-to-own financing you will not be considered for that financing option.
  </DetailText>
);

const shortenedText = (
  <DetailText>
    Consumer leasing is a financing option in which the provider will purchase merchandise from a merchant and lease it to you. Lease-to-own (or rent-to-own)
    financing are not loans. For example, instead of paying interest, you will pay rental fees on top of the retail price. You will not own the property until
    you complete all payments or take advantage of an Early Purchase Option under the full term of the agreement. The minimum lease term may vary with a maximum
    lease term of 48 months. Please review your lease agreement for complete terms and conditions.
  </DetailText>
);

const LeaseTermsTooltip: React.FC<LeaseTermsTooltipProps> = ({ styling, shortened = true }) => {
  return (
    <Popover>
      <PopoverTrigger
        style={{
          display: 'inline-block',
          background: 'none',
          border: 'none',
          textDecoration: 'underline',
          color: colors.koalafiBlue,
          fontFamily: fontFamilies.interSemiBold,
          fontSize: '0.75rem',
          padding: '0',
          ...styling,
        }}
      >
        lease-to-own
      </PopoverTrigger>
      <PopoverContent style={{ zIndex: '2', display: 'inline-block' }} className="Popover">
        <PopoverDescription>{shortened ? shortenedText : fulllText}</PopoverDescription>
      </PopoverContent>
    </Popover>
  );
};

export default LeaseTermsTooltip;
