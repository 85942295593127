import React from 'react';
import styled from '@emotion/styled';
import { colors, fontFamilies } from '../baseStyle/Variables';
import { ReactComponent as WallyHappy } from '../../assets/wally_happy.svg';
import { ReactComponent as WallySunglassesImage } from '../../assets/Wally_Sunglasses.svg';
import { ReactComponent as Confetti } from '../../assets/confetti.svg';
import { convertToCurrency } from '../../helpers/converters/converters';
import { AppMode } from '../../stores/order.store';
import LeaseTermsTooltip from '../lease-terms-tooltip/lease-terms-tooltip';
import LoanTermsTooltip from '../loans-terms-tooltip/loans-terms-tooltip';
import { AppStatuses, LoanAppStatus } from '../../gql/graphql';

type WallyApprovalProps = {
  isOTB: boolean;
  appMode: AppMode | null | undefined;
  financingAmount: string | undefined;
  status: string | undefined;
  isVirtualCard: boolean;
};

const ApprovalContainerStyle = styled.div`
  position: relative;
`;

const ConfettiStyle = styled.div<{ prequal?: boolean }>`
  position: absolute;
  width: 4rem;
  right: 3.5rem;
  top: ${(props: any) => (props.prequal ? '0' : '1.5rem')};
`;
const WallyStyle = styled.div`
  position: absolute;
  width: 5rem;
  right: 2rem;
  bottom: -2.4rem;
  z-index: 1; // without this, the gradient overlaps wally's feet
`;

const ApprovalText = styled.div<{ prequal?: boolean }>`
  color: ${colors.darkBlue};
  font-family: ${fontFamilies.gilroyExtraBold};
  font-size: ${(props: any) => (props.prequal ? '3.5rem' : '4rem')};
  line-height: 0.9;
  text-align: left;
`;

const ApprovalSubText = styled.div`
  color: ${colors.darkBlue};
  font-family: ${fontFamilies.interLight};
  font-size: 1.1rem;
  text-align: left;
  margin-top: 1rem;
`;

const CurrencyStyle = styled.div`
  font-family: ${fontFamilies.interBold};
  line-height: 1.4;
  font-size: 1.75rem;
`;

const WallyApproval: React.FC<WallyApprovalProps> = ({ isOTB, appMode, status, financingAmount, isVirtualCard }: WallyApprovalProps) => {
  const prequalifedText = !isOTB && status === AppStatuses.PreApproved;
  const approvalText = status === AppStatuses.Approved || status === LoanAppStatus.Approved || isOTB ? "You're approved" : "You're prequalified";
  const approvalAmount = isOTB ? convertToCurrency(parseFloat(financingAmount!)) : convertToCurrency(parseFloat(financingAmount!), 0);

  const getWallyImage = () => {
    if (!!isVirtualCard && (status === AppStatuses.Approved || status === LoanAppStatus.Approved)) {
      return (
        <WallyStyle>
          <WallySunglassesImage tabIndex={-1} />
        </WallyStyle>
      );
    } else {
      return (
        <>
          <ConfettiStyle prequal={prequalifedText}>
            <Confetti tabIndex={-1} />
          </ConfettiStyle>
          <WallyStyle>
            <WallyHappy tabIndex={-1} />
          </WallyStyle>
        </>
      );
    }
  };

  return (
    <ApprovalContainerStyle>
      {getWallyImage()}
      <ApprovalText prequal={prequalifedText}>{approvalText}</ApprovalText>
      <ApprovalSubText>
        {appMode === AppMode.lease ? (
          <React.Fragment>
            <div>
              for our <LeaseTermsTooltip styling={{ fontSize: '1.1rem' }} />
            </div>
            <div>financing up to</div>
          </React.Fragment>
        ) : (
          <>
            {' '}
            for a <LoanTermsTooltip styling={{ fontSize: '1.1rem' }} /> up to
          </>
        )}
        <CurrencyStyle>{approvalAmount}</CurrencyStyle>
      </ApprovalSubText>
    </ApprovalContainerStyle>
  );
};

export default WallyApproval;
