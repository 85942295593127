import { assign, createMachine, sendParent } from 'xstate';
import {
  CustomerLookupMachineEvents,
  CustomerLookupMachineGuards,
  CustomerLookupStates,
  CustomerLookupMachineStates,
  CustomerLookupMachineContext,
  CustomerLookupEvents,
} from './types/customerLookupMachine.types';
import { EcommMachineEvents } from './types/ecommMachine.types';
import { CustomerFlowType } from '../stores/applicationForm.store';
import { NavigationSections } from '../constants';
import { shouldTransferToLoans, shouldTransferToRapid, transferToLoansUi, transferToRapid } from '../helpers/cookies/unified.helper';
import { FormTypes } from '../gql/graphql';

export default createMachine<CustomerLookupMachineContext, CustomerLookupMachineEvents, CustomerLookupMachineStates>(
  {
    id: 'CustomerLookup',
    initial: CustomerLookupStates.CustLookupInitialState,
    // In future versions, there will be context that lives here instead of in redux
    context: {
      customerFlowType: null,
      wasCodeValidated: false,
      isCurrentApplyTypeOTB: false,
      order: null,
      skipToPage: null,
      isRedirecting: false, // used to prevent the redirect action from looping
      eligibilityDetails: null,
      highestSupportedApplicationMilestone: null,
    },
    states: {
      [CustomerLookupStates.CustLookupInitialState]: {
        always: [
          { target: CustomerLookupStates.OTPVerification, cond: 'Initial' },
          { target: CustomerLookupStates.BasicInfo, cond: 'SkipToBasics' },
          { target: CustomerLookupStates.BasicsContinued, cond: 'SkipToBasicsContinued' },
          { target: 'OTPVerification.OTPCheck', cond: 'SkipToOTPCheck' },
        ],
      },
      [CustomerLookupStates.OTPVerification]: {
        id: 'OTPVerification',
        initial: CustomerLookupStates.OTPRequest,
        states: {
          [CustomerLookupStates.OTPRequest]: {
            on: {
              [CustomerLookupEvents.REQUEST_OTP]: {
                target: CustomerLookupStates.OTPCheck,
              },
            },
            meta: {
              navigationSection: NavigationSections.HIDE_PROGRESS_BAR,
            },
          },
          [CustomerLookupStates.OTPCheck]: {
            on: {
              [CustomerLookupEvents.BACK]: {
                target: CustomerLookupStates.OTPRequest,
              },
              [CustomerLookupEvents.CHECK_OTP]: [
                {
                  target: '#CustomerLookup.CustomerSearch',
                  actions: [
                    assign({
                      customerFlowType: (context, event) => event?.data?.customerFlowType,
                      wasCodeValidated: (context, event) => !!event?.data?.wasCodeValidated,
                      isCurrentApplyTypeOTB: (context, event) => !!event?.data?.isCurrentApplyTypeOTB,
                      order: (context, event) => event?.data?.order,
                      eligibilityDetails: (context, event) => event?.data?.eligibilityDetails,
                      highestSupportedApplicationMilestone: (context, event) => event?.data?.highestSupportedApplicationMilestone,
                    }),
                  ],
                },
              ],
            },
            meta: {
              navigationSection: NavigationSections.HIDE_PROGRESS_BAR,
              canGoBackViaHeader: true,
            },
          },
        },
      },
      [CustomerLookupStates.CustomerSearch]: {
        id: 'customer_search',
        initial: CustomerLookupStates.Loading,
        states: {
          [CustomerLookupStates.Loading]: {
            tags: 'Loading',
            always: [
              // TODO: think about assigning event to context before checking guards
              { cond: 'IsRedirecting' }, // used to stop checking transitions while redirecting
              { target: '#CustomerLookup.OTB', cond: 'CustomerOTB' },
              { target: '#CustomerLookup.Last4SSN', cond: 'ExistingCustomerFound' },
              { actions: 'transferToLoansUi', cond: 'ShouldTransferToLoans' },
              { actions: 'transferToRapid', cond: 'ShouldTransferToRapid' }, // TODO: remove
              { target: '#CustomerLookup.LongFormApplication', cond: 'IsLongApp' }, // starting a new long form app
              { target: '#CustomerLookup.BasicInfo', cond: 'CustomerNotFound' },
              { target: '#CustomerLookup.Decline', cond: 'CustomerDeclined' },
            ],
            meta: {
              navigationSection: NavigationSections.HIDE_PROGRESS_BAR,
            },
          },
        },
      },
      [CustomerLookupStates.OTB]: {
        type: 'final',
        entry: [
          sendParent((context, event) => ({
            data: {
              ...context,
            },
            type: EcommMachineEvents.OTB_CUSTOMER,
          })),
        ],
      },
      [CustomerLookupStates.Last4SSN]: {
        on: {
          [CustomerLookupEvents.APPLICATION_STATUS]: [
            // TODO: think about assigning event to context before checking guards
            { cond: 'IsRedirecting' }, // used to stop checking transitions while redirecting
            { target: 'ExistingApplication', cond: 'IsPostApproval' }, // go to approval confirmed stop screen instead of transfering
            { actions: 'transferToLoansUi', cond: 'ShouldTransferToLoans' },
            { actions: 'transferToRapid', cond: 'ShouldTransferToRapid' }, // TODO: remove
            {
              target: CustomerLookupStates.LongFormApplication,
              cond: 'IsLongApp',
              actions: [assign({ order: (context, event) => event?.data?.order })],
            },
            { target: '#CustomerLookup.BasicInfo', cond: 'IsDraftShortApp' },
            {
              target: CustomerLookupStates.AccommodationAddressErrors,
              cond: 'LeaseWithPreapprovalError',
              actions: [assign({ order: (context, event) => event?.data?.order })],
            },
            { target: 'ExistingApplication' },
          ],
          [CustomerLookupEvents.OTB_APPLICATION]: {
            target: '#CustomerLookup.OTB',
          },
          [CustomerLookupEvents.NEW_APPLICATION]: [
            { cond: 'IsRedirecting' }, // used to stop checking transitions while redirecting
            { actions: 'transferToLoansUi', cond: 'ShouldTransferToLoans' },
            { actions: 'transferToRapid', cond: 'ShouldTransferToRapid' }, // TODO: remove
            { target: CustomerLookupStates.LongFormApplication, cond: 'IsLongApp' },
            { target: 'BasicInfo' },
          ],
          [CustomerLookupEvents.NOT_RECOGNIZED]: [
            { cond: 'IsRedirecting' }, // used to stop checking transitions while redirecting
            { actions: 'transferToLoansUi', cond: 'ShouldTransferToLoans' },
            { actions: 'transferToRapid', cond: 'ShouldTransferToRapid' }, // TODO: remove
            { target: CustomerLookupStates.LongFormApplication, cond: 'IsLongApp' },
            { target: 'NoAccountFound' },
          ],
          [CustomerLookupEvents.NEXT]: {
            target: 'Decline',
          },
          [CustomerLookupEvents.CUST_ID_PROGRAM]: {
            target: 'CustomerIdentification',
          },
          [CustomerLookupEvents.TERMS_AND_CONDITIONS]: {
            target: 'TermsAndConditions',
          },
          [CustomerLookupEvents.AUTH_USER_TERMS]: {
            target: 'AuthorizedUserTerms',
          },
        },
        meta: {
          navigationSection: NavigationSections.CHECK_ELIGIBILITY,
        },
      },
      [CustomerLookupStates.CustomerIdentification]: {
        on: {
          [CustomerLookupEvents.NEXT]: {
            target: [CustomerLookupStates.Last4SSN],
          },
        },
      },
      [CustomerLookupStates.TermsAndConditions]: {
        on: {
          [CustomerLookupEvents.NEXT]: {
            target: [CustomerLookupStates.Last4SSN],
          },
        },
      },
      [CustomerLookupStates.AuthorizedUserTerms]: {
        on: {
          [CustomerLookupEvents.NEXT]: {
            target: [CustomerLookupStates.Last4SSN],
          },
        },
      },
      [CustomerLookupStates.ExistingApplication]: {
        type: 'final',
        entry: [
          sendParent((context, event) => ({
            type: EcommMachineEvents.EXISTING_APPLICATION,
            data: {
              appMode: event?.data?.appMode,
              order: event?.data?.order,
              skipApproval: false,
            },
          })),
        ],
      },
      [CustomerLookupStates.BasicInfo]: {
        on: {
          [CustomerLookupEvents.NEXT]: {
            target: [CustomerLookupStates.BasicsContinued],
          },
        },
        meta: {
          navigationSection: NavigationSections.CHECK_ELIGIBILITY,
        },
      },
      [CustomerLookupStates.NoAccountFound]: {
        on: {
          [CustomerLookupEvents.NEXT]: {
            target: [CustomerLookupStates.BasicsContinued],
          },
        },
        meta: {
          navigationSection: NavigationSections.CHECK_ELIGIBILITY,
        },
      },
      [CustomerLookupStates.BasicsContinued]: {
        on: {
          [CustomerLookupEvents.NEXT]: [
            {
              target: [CustomerLookupStates.Ineligible],
              cond: 'IsIneligible',
              actions: assign({
                eligibilityDetails: (_context, event) => event?.data?.eligibilityDetails,
              }),
            },
            /**
             * If we find that the customer is OTB eligible after collecting date of birth and full SSN, the OTB machine will be invoked.
             * This means that they will immediately see the SSN last four verification screen.  This was a consience desision to satisfy
             * compliance requirements around providing appropriate disclosures before application submission.  This isn't really the cleanest
             * experience, so consider revisiting this approach in the future.
             */
            {
              target: [CustomerLookupStates.OTB],
              cond: (_context, event) => !!event.data?.isCurrentApplyTypeOTB,
            },
            {
              type: 'final',
              actions: sendParent((context, event) => ({
                type: EcommMachineEvents.NEW_APPLICATION,
              })),
            },
          ],
          [CustomerLookupEvents.BACK]: {
            target: [CustomerLookupStates.BasicInfo],
          },
        },
        meta: {
          navigationSection: NavigationSections.CHECK_ELIGIBILITY,
          canGoBackViaHeader: true,
        },
      },
      [CustomerLookupStates.Decline]: {},
      [CustomerLookupStates.LongFormApplication]: {
        type: 'final', // note: long running actions such as log may be killed by 'final'
        entry: [
          sendParent((context, event) => ({
            data: {
              order: context?.order,
              highestSupportedApplicationMilestone: context?.highestSupportedApplicationMilestone,
            },
            type: EcommMachineEvents.LONG_FORM,
          })),
        ],
      },
      [CustomerLookupStates.AccommodationAddressErrors]: {
        on: {
          [CustomerLookupEvents.BACK]: [
            {
              cond: 'LeaseWithSsnDeclineReason',
              target: CustomerLookupStates.BasicsContinued,
            },
            {
              cond: 'LeaseWithAddressDeclineReason',
              actions: sendParent((context, event) => ({
                type: EcommMachineEvents.NEW_APPLICATION,
              })),
            },
          ],
        },
      },
      [CustomerLookupStates.Ineligible]: {},
    },
    schema: {
      context: {} as CustomerLookupMachineContext,
      events: {} as CustomerLookupMachineEvents,
      guards: {} as CustomerLookupMachineGuards,
    },
    predictableActionArguments: true,
    preserveActionOrder: true,
  },
  {
    actions: {
      transferToLoansUi: (context, event) => {
        transferToLoansUi();
        context.isRedirecting = true;
      },
      transferToRapid: (context, event) => {
        transferToRapid();
        context.isRedirecting = true;
      },
      // strictly for debugging, do not remove and make sure you clean up references in MRs
      log: (context, event) => {
        console.log('CustomerLookup');
        console.log('context', context);
        console.log('event', event);
      },
    },
    guards: {
      ExistingCustomerFound: (context, event) => {
        return context.customerFlowType === CustomerFlowType.SingleCustomer || context.customerFlowType === CustomerFlowType.MultipleCustomers;
      },
      CustomerOTB: (context, event) => {
        return context.isCurrentApplyTypeOTB;
      },
      CustomerDeclined: (context, event) => {
        return false;
      },
      CustomerNotFound: (context, event) => {
        // Goes to Basics Continued if the customer is a new customer
        const custToBasics = context.customerFlowType === CustomerFlowType.NewCustomer;
        return custToBasics;
        // return context.order!.application.lease == null && context.order!.application.loan == null;
      },
      SkipToBasicsContinued: (context, event) => {
        return context.skipToPage === 'BasicInfoContinued';
      },
      SkipToBasics: (context, event) => {
        return context.skipToPage === 'BasicInfo';
      },
      SkipToOTPCheck: (context, event) => {
        return context.skipToPage === 'OTPCheck';
      },
      Initial: (context, event) => {
        return !context.skipToPage;
      },
      ShouldTransferToLoans: (context, event) => {
        // this doesnt check state, so target order really matters
        return !context.isRedirecting && shouldTransferToLoans();
      },
      ShouldTransferToRapid: (context, event) => {
        // this doesnt check state, so target order really matters
        return (
          !context.isRedirecting &&
          shouldTransferToRapid() &&
          (!context.highestSupportedApplicationMilestone ||
            context.highestSupportedApplicationMilestone < 2 ||
            (context.highestSupportedApplicationMilestone === 2 &&
              (event.data?.order?.application?.lease?.status === 'errorsFound' || event.data?.order?.application?.lease?.status === 'preApproved'))) // Temporarily send customers with existing pre-approved apps to RAPID for M2
        );
      },
      IsRedirecting: (context, event) => {
        return context.isRedirecting;
      },
      IsPostApproval: (context, event) => {
        // used to stop resumed apps that are already approved from transfering to rapid/reapplying
        // this should technically need a b&m check, however the default case for the block covers it
        return (
          event.data?.order?.application?.lease?.status === 'approved' ||
          event.data?.order?.application?.lease?.status === 'docsReady' ||
          event.data?.order?.application?.lease?.status === 'docsSigned'
        );
      },
      IsDraftShortApp: (context, event) => {
        return event.data?.order?.application?.lease?.status === 'draft' && event.data?.order?.dealer?.formType === FormTypes.Short;
      },
      IsLongApp: (context, event) => {
        const isLongFormType = context.order?.dealer?.formType === FormTypes.Long || context.order?.dealer?.formType === FormTypes.LongV2;
        return isLongFormType && shouldTransferToRapid();
      },
      LeaseWithPreapprovalError: (context, event) => {
        return (
          event.data?.order?.application?.lease?.status === 'errorsFound' &&
          ['address', 'ssn'].includes(event?.data?.order?.application?.lease?.declineReason || '')
        );
      },
      LeaseWithSsnDeclineReason: (context, event) => {
        const declineReason = context?.order?.application?.lease?.declineReason;
        return declineReason === 'ssn';
      },
      LeaseWithAddressDeclineReason: (context, event) => {
        const declineReason = context?.order?.application?.lease?.declineReason;
        return declineReason === 'address';
      },
      IsIneligible: (_context, event) => {
        return event?.data?.eligibilityDetails?.isEligible === false || event.data?.eligibilityDetails?.otbCode === 4;
      },
    },
  },
);
