import { EventObject, Typestate } from 'xstate';
import { AppMode } from '../../stores/order.store';
import { Order } from '../../gql/graphql';

export type EcommMachineMachineContext = {
  orderId: string;
  order: Order | null;
  customerLookupStartingPage?: string | null;
  existingApplicationStartingPage?: string | null;
  longFormApplicationStartingPage?: string | null;
  entryPointPage: string;
  appMode: AppMode;
  ecommApprovalOnly: boolean;
  highestSupportedApplicationMilestone: number;
  reviewPage: boolean;
  plaidFF: boolean;
  plaidAB: boolean;
};

export enum EcommMachineStates {
  EcommMachine = 'EcommMachine',
  Loading = 'Loading',
  EntryPoint = 'EntryPoint',
  ExistingApplication = 'ExistingApplication',
  CustomerLookupFlow = 'CustomerLookupFlow',
  VirtualCard = 'VirtualCard',
  NewApplication = 'NewApplication',
  AnotherTestState = 'AnotherTestState',
  OTB = 'OTB',
  WelcomeBack = 'WelcomeBack',
  Signed = 'Signed',
  Declined = 'Declined',
  LoanDeclined = 'LoanDeclined',
  LeaseDeclined = 'LeaseDeclined',
  LongFormApplication = 'LongFormApplication',
}

export interface EcommMachineMachineStatesSchema {
  [EcommMachineStates.EcommMachine]: {};
  [EcommMachineStates.Loading]: {};
  [EcommMachineStates.ExistingApplication]: {};
  [EcommMachineStates.CustomerLookupFlow]: {};
  [EcommMachineStates.VirtualCard]: {};
  [EcommMachineStates.OTB]: {};
  [EcommMachineStates.NewApplication]: {};
  [EcommMachineStates.AnotherTestState]: {};
  [EcommMachineStates.WelcomeBack]: {};
  [EcommMachineStates.Signed]: {};
  [EcommMachineStates.Declined]: {};
  [EcommMachineStates.LoanDeclined]: {};
  [EcommMachineStates.LeaseDeclined]: {};
  [EcommMachineStates.LongFormApplication]: {};
}

export interface EcommMachineMachineStates extends Typestate<EcommMachineMachineContext> {
  type: EcommMachineMachineStatesSchema;
}

export enum EcommMachineEvents {
  APP_IN_PROGRESS = 'AppInProgress',
  APP_FINISHED = 'AppFinished',
  VIRTUAL_CARD_APP = 'VirtualCardApp',
  CUSTOMER_LOOKUP_EVENT = 'CustomerLookupEvent',
  NEW_APPLICATION = 'NewApplication',
  EXISTING_APPLICATION = 'ExistingApplication',
  OTB_CUSTOMER = 'OtbCustomer',
  LONG_FORM = 'LongForm',
  NEXT = 'NEXT',
  BACK = 'BACK',
  DECLINE = 'Decline',
  ERRROR = 'Error',
  PAYMENT_ERROR = 'PaymentError',
}

type EcommMachineEventsTypeSchema =
  | EcommMachineEvents.APP_IN_PROGRESS
  | EcommMachineEvents.APP_FINISHED
  | EcommMachineEvents.VIRTUAL_CARD_APP
  | EcommMachineEvents.CUSTOMER_LOOKUP_EVENT
  | EcommMachineEvents.NEW_APPLICATION
  | EcommMachineEvents.EXISTING_APPLICATION
  | EcommMachineEvents.LONG_FORM
  | EcommMachineEvents.NEXT
  | EcommMachineEvents.BACK
  | EcommMachineEvents.DECLINE
  | EcommMachineEvents.ERRROR
  | EcommMachineEvents.OTB_CUSTOMER
  | EcommMachineEvents.PAYMENT_ERROR;

export interface EcommMachineMachineEvents extends EventObject {
  type: EcommMachineEventsTypeSchema;
  order?: any;
  skipPage?: any;
  appMode?: AppMode;
}

// export enum EcommMachineGuardsType {
//   EXISTING_CUSTOMER_FOUND = 'ExistingCustomerFound',
//   CUSTOMER_OTB = 'CustomerOTB',
//   CUSTOMER_DECLINED = 'CustomerDeclined',
//   CUSTOMER_NOT_FOUND = 'CustomerNotFound',
// }

// type EcommMachineGuardsTypeSchema =
//   | EcommMachineGuardsType.EXISTING_CUSTOMER_FOUND
//   | EcommMachineGuardsType.CUSTOMER_OTB
//   | EcommMachineGuardsType.CUSTOMER_NOT_FOUND
//   | EcommMachineGuardsType.CUSTOMER_DECLINED;

// export interface EcommMachineMachineGuards extends EventObject {
//   type: EcommMachineGuardsTypeSchema;
// }
