import { ApolloQueryResult } from '@apollo/client';
import { action, Action, computed, Computed, thunk, Thunk } from 'easy-peasy';
import { Errors } from '../constants';
import {
  AnalyticsInput,
  Application,
  ApplyInput,
  ApplyTypes,
  AuthorizeCardInput,
  AuthorizeCardResponse,
  BankAccountInput,
  CartInput,
  CreateDraftAppInput,
  Customer,
  CustomerEligibility,
  CustomerIdentityInput,
  CustomerSearchErrors,
  CustomerSearchInput,
  DebitDetailsInput,
  EmploymentInput,
  GenerateContractErrors,
  GenerateLeaseContractInput,
  GetAdditionalCustomerInfoQuery,
  LeaseDocuments,
  PayPeriodTypes,
  SelectLeaseOptionErrors,
  SelectLeaseOptionInput,
  ShippingAddressInput,
  SigningErrors,
  SignLeaseAgreementInput,
  SoftDecline,
  SoftDeclineExtension,
  SubmitApplicationError,
  UpdateAddressErrors,
  UpdateBankAccountErrors,
  UpdateBankAccountInput,
  UpdateBasicInfoContinuedInput,
  UpdateCustomerErrors,
  UpdateDebitErrors,
  UpdateDebitInfoInput,
  UpdateEmploymentErrors,
  UpdateShippingAddressInput,
  VerifyEmailQuery,
  BillingAddressInput,
  AccommodationAddressInput,
} from '../gql/graphql';
import { getCardProvider } from '../helpers';
import { shouldProcessNeuroId } from '../helpers/neuroId/neuroId.helper';
import { createForbiddenErrorMessage, createUnauthorizedErrorMessage } from '../helpers/security/security';
import { AppModel } from './storeModel';

export interface BasicInfoModel {
  firstName: string | undefined;
  lastName: string | undefined;
  emailAddress: string | null | undefined;
}

export interface BasicInfoContinuedModel {
  birthDate: string;
  taxpayerIdentificationNumber?: string;
  tokenizedTaxId?: string;
  monthlyIncome?: string;
}

interface UpdateEmploymentThunkPayload {
  applicationId: number;
}

export interface AdditionalCustomerInfoModel {
  fullTaxId: string;
  fullBankAccount: string;
  cardNumberWithBin: string;
}

interface FilledApplication {
  customer: CustomerIdentityInput;
  employment: EmploymentInput;
}

export interface PayFrequencyModel {
  paymentFrequency: PayPeriodTypes | null;
  nextPayDate?: string;
  lastPayDate: string;
  isPaidViaDirectDeposit?: boolean;
}

export interface ResolverError {
  message: string;
  path: string[];
}

export enum CustomerFlowType {
  NewCustomer = 'NewCustomer', // Customer not found in customer SoR
  SingleCustomer = 'SingleCustomer', // Customer uniquely found by phone # in customer SoR but IDV has not occured yet
  MultipleCustomers = 'MultipleCustomers', // Multiple customers found in customer SoR by phone # with no IDV yet or multiple matches of last4 when attempting IDV
  VerifiedCustomer = 'VerifiedCustomer', // Customer has passed idv check (either last 4 verified or full SSN/DOB)
}

export interface ApplicationFormModel {
  application?: Application;
  leaseDocuments?: LeaseDocuments;
  applicationForm?: ApplyInput;

  // Update Debit Info
  debitInfo: DebitDetailsInput;
  setDebitInfo: Action<ApplicationFormModel, DebitDetailsInput>;
  updateDebitInfo: Thunk<ApplicationFormModel, UpdateDebitInfoInput, any, AppModel>;
  updateDebitInfoErrors: UpdateDebitErrors[];
  wasDebitInfoUpdated: boolean;
  setWasDebitInfoUpdated: Action<ApplicationFormModel, boolean>;
  setUpdateDebitInfoErrors: Action<ApplicationFormModel, UpdateDebitErrors[]>;

  // Authorize Card
  authorizeCard: Thunk<ApplicationFormModel, AuthorizeCardInput, any, AppModel>;
  authorizeCardResponse: AuthorizeCardResponse;
  wasCardAuthorized: boolean;
  setWasCardAuthorized: Action<ApplicationFormModel, boolean>;
  setAuthorizeCardResponse: Action<ApplicationFormModel, AuthorizeCardResponse>;

  // Select lease option
  selectedLeaseOption: SelectLeaseOptionInput | undefined;
  setSelectedLeaseOption: Action<ApplicationFormModel, SelectLeaseOptionInput>;
  selectLeaseOption: Thunk<ApplicationFormModel, SelectLeaseOptionInput, any, AppModel>;
  wasLeaseOptionSelected: boolean;
  setWasLeaseOptionSelected: Action<ApplicationFormModel, boolean>;
  selectLeaseOptionErrors: SelectLeaseOptionErrors[];
  setSelectLeaseOptionErrors: Action<ApplicationFormModel, SelectLeaseOptionErrors[]>;

  // Lease Agreement
  signLeaseAgreement: Thunk<ApplicationFormModel, SignLeaseAgreementInput, any, AppModel>;
  leaseAgreementSigned: boolean;
  setLeaseAgreementSigned: Action<ApplicationFormModel, boolean>;
  signLeaseAgreementErrors: SigningErrors[];
  setSignLeaseAgreementErrors: Action<ApplicationFormModel, SigningErrors[]>;

  // General form error
  error?: ErrorMessage | null;
  setErrorGeneral: Action<ApplicationFormModel, any>;

  // loading form action
  isLoading: boolean;
  setIsLoading: Action<ApplicationFormModel, boolean>;

  // form fields
  cellPhone: string;
  setCellPhone: Action<ApplicationFormModel, string>;
  basicInfo: BasicInfoModel;
  setBasicInfo: Action<ApplicationFormModel, BasicInfoModel>;
  setBasicInfoContinued: Action<ApplicationFormModel, BasicInfoContinuedModel>;
  basicInfoContinued: BasicInfoContinuedModel;
  accommodationAddress: AccommodationAddressInput;
  billingAddress: BillingAddressInput;
  shippingAddress: ShippingAddressInput | undefined;

  setAccommodationAddress: Action<ApplicationFormModel, AccommodationAddressInput>;
  setBillingAddress: Action<ApplicationFormModel, BillingAddressInput>;
  setShippingAddress: Action<ApplicationFormModel, ShippingAddressInput>;

  updateBasicInfoContinued: Thunk<ApplicationFormModel, UpdateBasicInfoContinuedInput, any, AppModel>;
  updateBasicInfoContinuedErrors: UpdateCustomerErrors[];
  setUpdateBasicInfoContinuedErrors: Action<ApplicationFormModel, UpdateCustomerErrors[]>;
  wasBasicInfoContinuedUpdated: boolean;
  setWasBasicInfoContinuedUpdated: Action<ApplicationFormModel, boolean>;

  bankAccount: BankAccountInput | undefined;
  setBankAccount: Action<ApplicationFormModel, BankAccountInput | undefined>;
  updateBankAccount: Thunk<ApplicationFormModel, UpdateBankAccountInput, any, AppModel>;
  updateBankAccountErrors: UpdateBankAccountErrors[];
  setUpdateBankAccountErrors: Action<ApplicationFormModel, UpdateBankAccountErrors[]>;
  wasBankAccountUpdated: boolean;
  setBankAccountUpdated: Action<ApplicationFormModel, boolean>;

  // PayFrequency page / updateEmploymentInfo AES mutation
  // updateEploymentInput doesn't match what we are saving in the store
  payFrequency: PayFrequencyModel;
  setPayFrequency: Action<ApplicationFormModel, PayFrequencyModel>;
  employmentWasUpdated: boolean;
  updateEmployment: Thunk<ApplicationFormModel, UpdateEmploymentThunkPayload, any, AppModel>;
  updateEmploymentErrors: UpdateEmploymentErrors[];
  setUpdateEmploymentErrors: Action<ApplicationFormModel, UpdateEmploymentErrors[]>;
  setEmploymentWasUpdated: Action<ApplicationFormModel, boolean>;

  // Review & Submit Page
  additionalCustomerInfo: AdditionalCustomerInfoModel;
  getAdditionalCustomerInfo: Thunk<ApplicationFormModel, number, any, AppModel>;
  setAdditionalCustomerInfo: Action<ApplicationFormModel, AdditionalCustomerInfoModel>;

  //thunks and setters for thunks
  createDraftApp: Thunk<ApplicationFormModel, any | undefined, any, AppModel>;
  apply: Thunk<ApplicationFormModel, AnalyticsInput | undefined, any, AppModel>;
  generateContract: Thunk<ApplicationFormModel, GenerateLeaseContractInput, any, AppModel>;
  setApplication: Action<ApplicationFormModel, Application | undefined>;
  setApplyErrors: Action<ApplicationFormModel, SubmitApplicationError[]>;
  setLeaseDocuments: Action<ApplicationFormModel, LeaseDocuments>;
  setGenerateContractErrors: Action<ApplicationFormModel, GenerateContractErrors[]>;
  applyErrors: SubmitApplicationError[];
  generateContractErrors: GenerateContractErrors[];

  filledApplication: Computed<ApplicationFormModel, FilledApplication>;

  updateShippingAddress: Thunk<ApplicationFormModel, UpdateShippingAddressInput, any, AppModel>;
  shippingAddressUpdated?: boolean;
  setShippingAddressUpdated: Action<ApplicationFormModel, boolean>;

  updateAddressErrors: UpdateAddressErrors[];
  setUpdateAddressErrors: Action<ApplicationFormModel, UpdateAddressErrors[]>;
  verifyEmail: Thunk<ApplicationFormModel, string, any, AppModel>;
  // fetching and storing client IP address
  clientIp: string;
  setClientIp: Action<ApplicationFormModel, string>;
  fetchClientIp: Thunk<ApplicationFormModel, any, any, AppModel>;
  /*******************
   /customerSearch Eligiblity  *
   /***************** */
  wasEligibilityUpdated: boolean;
  setEligibilityUpdated: Action<ApplicationFormModel, boolean>;
  eligibilityDetails: CustomerEligibility | undefined;
  setEligibilityDetails: Action<ApplicationFormModel, CustomerEligibility | null>;
  currentApplyType: Computed<ApplicationFormModel, ApplyTypes | null>;
  isCurrentApplyTypeOTB: Computed<ApplicationFormModel, boolean>;

  /*******************
   /     Customer     *
   /***************** */
  customerSearchByCellPhone: Thunk<ApplicationFormModel, CustomerSearchInput, any, AppModel>;
  setCustomers: Action<ApplicationFormModel, Customer[]>;
  customerSearchErrors: CustomerSearchErrors[];
  setCustomerSearchErrors: Action<ApplicationFormModel, CustomerSearchErrors[]>;
  customers: Customer[] | null;
  customerFlowType?: CustomerFlowType;
  setCustomerFlowType: Action<ApplicationFormModel, CustomerFlowType>;

  //Resolver Errors that are not our types
  resolverErrors: ResolverError[];
  setResolverErrors: Action<ApplicationFormModel, ResolverError[]>;

  wasFormEdited: boolean;
  setWasFormEdited: Action<ApplicationFormModel, boolean>;
}

export interface ErrorMessage {
  developerText?: string;
  displayText?: string;
  stackTrace?: any;
}

/**
 * verifyEmail thunk sends VerifyEmail query to validate email input against RegEx and domain.
 *
 * The thunk returns error in case of invalid email response.
 */
const verifyEmail = thunk<ApplicationFormModel, string>((actions, email, { injections }): Promise<Errors | null> => {
  const { applicationFormService } = injections;

  return applicationFormService
    .verifyEmail(email)
    .then((res: ApolloQueryResult<VerifyEmailQuery>) => {
      // return Apollo client error if any
      if (res.error || res.errors) return Errors.ErrGeneral;
      // return email validation error if any
      if (res.data.verifyEmail.verifyEmailErrors.length > 0) return res.data.verifyEmail.verifyEmailErrors[0].message;
      // return null if no errors
      else return null;
    })
    .catch((err: any) => {
      console.error(err);
      return Errors.ErrGeneral;
    });
});

const applicationForm: ApplicationFormModel = {
  /**
   * GENERAL ERRORS: GET/SET
   * App errors, general non-query-specific related errors
   */
  applyErrors: [],
  resolverErrors: [],
  updateEmploymentErrors: [],
  generateContractErrors: [],
  updateAddressErrors: [],
  updateDebitInfoErrors: [],
  signLeaseAgreementErrors: [],
  error: null,
  cellPhone: '',
  accommodationAddress: {
    line1: '',
    city: '',
    state: '',
    zip: '',
  },
  billingAddress: {
    line1: '',
    city: '',
    state: '',
    zip: '',
  },
  shippingAddress: undefined,
  basicInfo: {
    firstName: '',
    lastName: '',
    emailAddress: '',
  },
  payFrequency: {
    paymentFrequency: null,
    lastPayDate: '',
    isPaidViaDirectDeposit: undefined,
  },
  clientIp: 'ipAddressNotSet',
  setErrorGeneral: action((state, payload?) => {
    state.error = payload;
  }),
  setClientIp: action((state, payload) => {
    state.clientIp = payload;
  }),
  createDraftApp: thunk(async (actions, analytics, helpers) => {
    const { applicationFormService } = helpers.injections;
    const applicationFormStore = helpers.getStoreState().applicationForm;
    const orderState = helpers.getStoreState().order;
    const orderActions = helpers.getStoreActions().order;
    const authActions = helpers.getStoreActions().auth;

    let filledApp = applicationFormStore.filledApplication;
    let crateDraftAppInput: CreateDraftAppInput = {
      employment: filledApp.employment,
      customer: filledApp.customer,
      orderId: orderState.orderId ?? '',
    };

    crateDraftAppInput.analytics = analytics;
    crateDraftAppInput.analytics!.neuroIdIdentityId = orderState.neuroIdIdentityId;

    actions.setIsLoading(true);
    actions.setErrorGeneral(null);

    let response;

    try {
      response = await applicationFormService.createDraftApp(crateDraftAppInput);

      if (response?.data?.createDraftApp?.createDraftAppErrors.length > 0) {
        actions.setErrorGeneral(response?.data?.createDraftApp?.createDraftAppErrors[0]);
      }

      if (!!response?.data.createDraftApp?.leaseApplicationId) {
        const applicationDetails = {
          id: response?.data.createDraftApp?.leaseApplicationId,
        };

        actions.setApplication(applicationDetails as unknown as Application);
        orderActions.queryOrder(orderState.orderId!);

        // these need to default to false to prevent a second get
        const highestSupportedApplicationMilestone = helpers.getStoreState().featureFlags.highestSupportedApplicationMilestone || null;
        const role = helpers.getStoreState().auth.role || null;
        const isVirtualCard = helpers.getStoreState().order.order?.dealer?.isVirtualCard || false;
        const channelType = helpers.getStoreState().order.order?.dealer?.channelType;
        const neuroid_enabled = helpers.getStoreState().featureFlags?.featureFlags?.neuroid_enabled;
        if (shouldProcessNeuroId({ highestSupportedApplicationMilestone, role, isVirtualCard, channelType, neuroid_enabled }))
          nid('setRegisteredUserId', response?.data.createDraftApp?.customerId.toString());

        actions.setCustomerFlowType(CustomerFlowType.SingleCustomer); // should this be VerifiedCustomer?
      }
    } catch (err: any) {
      switch (err?.networkError?.statusCode) {
        case 403:
          const forbiddenErrMsg = createForbiddenErrorMessage(err);
          authActions.setForbiddenError(forbiddenErrMsg);
          break;
        case 401:
          const unauthErrMsg = createUnauthorizedErrorMessage(err);
          authActions.setUnauthorizedError(unauthErrMsg);
          break;
        default:
          actions.setErrorGeneral(err);
      }
    }

    actions.setIsLoading(false);
  }),
  apply: thunk(async (actions, analytics, helpers) => {
    const { applicationFormService } = helpers.injections;
    const applicationFormStore = helpers.getStoreState().applicationForm;
    const orderActions = helpers.getStoreActions().order;
    const orderState = helpers.getStoreState().order;
    const authActions = helpers.getStoreActions().auth;

    actions.setIsLoading(true);

    let filledApp = applicationFormStore.filledApplication;
    let applyInput: ApplyInput = {
      customer: filledApp.customer,
      employment: filledApp.employment,
      orderId: orderState.orderId ?? '',
    };
    let cartInput: CartInput | undefined;

    if (orderState.order?.details?.customer?.shippingAddress) {
      applyInput.customer.shippingAddress = orderState.order.details.customer.shippingAddress;
    }

    if (orderState.hasItems) {
      cartInput = {
        items: orderState.order?.details.items!,
        shippingAmount: orderState.order?.details.shippingAmnt!,
        taxAmnt: orderState.order?.details.taxAmnt,
      };
    }

    applyInput.cart = cartInput;
    applyInput.analytics = analytics;
    applyInput.analytics!.neuroIdIdentityId = orderState.neuroIdIdentityId;

    // need to clear errors so they don't persist on application retry
    actions.setApplyErrors([]);
    actions.setErrorGeneral(null);
    actions.setWasFormEdited(false);

    let response;

    try {
      response = await applicationFormService.apply({
        applyInput: applyInput,
      });

      // these need to default to false to prevent a second get
      const highestSupportedApplicationMilestone = helpers.getStoreState().featureFlags.highestSupportedApplicationMilestone || null;
      const role = helpers.getStoreState().auth.role || null;
      const isVirtualCard = helpers.getStoreState().order.order?.dealer?.isVirtualCard || false;
      const channelType = helpers.getStoreState().order.order?.dealer?.channelType;
      const neuroid_enabled = helpers.getStoreState().featureFlags?.featureFlags?.neuroid_enabled;
      if (shouldProcessNeuroId({ highestSupportedApplicationMilestone, role, isVirtualCard, channelType, neuroid_enabled })) nid('applicationSubmit');

      if (response?.data?.apply?.applicationErrors.length > 0) {
        // Need to setApplyErrors before looking into types of errors for UI to function properly
        actions.setApplyErrors(response.data.apply.applicationErrors);
        const softDeclineErr = response.data.apply.applicationErrors.find((err: SoftDecline) => err.__typename === 'SoftDecline');
        // For softDecline app retry to work, we need to get data from softDecline error
        if (softDeclineErr) {
          const errorDetails: SoftDeclineExtension = {
            ...softDeclineErr.extensions,
          };

          const applicationDetails = {
            id: errorDetails.appId,
            // connor adjust this
            declineReason: errorDetails.declineField,
          };
          actions.setApplication(applicationDetails as unknown as Application);
          // TODO: ecomm currently does not have BAV soft declines, when BAV dynamic app is added, need to have conditional to set to Verified customer on BAV soft decline (like app UI)
          orderActions.queryOrder(orderState.orderId!);
          actions.setCustomerFlowType(CustomerFlowType.SingleCustomer);
        }
      } else {
        // Moving this to an else block. We are returning an application when there are SoftDecline/HardDecline errors.
        // But that is temporary. We want our app to not rely on that application object when we have errors.
        actions.setApplication(response?.data?.apply?.application);
        // We know order id has to exist at this point, because will never call apply without order id available.
        // TODO - confirm with Britz, hard/soft declines will not make it to this block
        orderActions.queryOrder(orderState.orderId!);
        actions.setCustomerFlowType(CustomerFlowType.VerifiedCustomer); // newCustomer becomes "verified" after approved submit

        // these need to default to false to prevent a second get
        if (shouldProcessNeuroId({ highestSupportedApplicationMilestone, role, isVirtualCard, channelType, neuroid_enabled }))
          nid('setRegisteredUserId', response?.data?.apply?.application?.customer?.id.toString());
      }
    } catch (err: any) {
      switch (err?.networkError?.statusCode) {
        case 403:
          const forbiddenErrMsg = createForbiddenErrorMessage(err);
          authActions.setForbiddenError(forbiddenErrMsg);
          break;
        case 401:
          const unauthErrMsg = createUnauthorizedErrorMessage(err);
          authActions.setUnauthorizedError(unauthErrMsg);
          break;
        default:
          actions.setErrorGeneral(err);
      }
    }

    actions.setIsLoading(false);
  }),
  generateContract: thunk(async (actions, applicationId, helpers) => {
    const { applicationFormService } = helpers.injections;
    const authActions = helpers.getStoreActions().auth;

    actions.setIsLoading(true);

    try {
      const response = await applicationFormService.generateContract({ generateContract: applicationId });

      if (response?.data?.generateLeaseContract?.generateContractErrors.length !== 0) {
        actions.setGenerateContractErrors(response?.data?.generateLeaseContract.generateContractErrors);
      } else {
        actions.setLeaseDocuments(response?.data?.generateLeaseContract?.leaseDocuments);
      }
    } catch (err: any) {
      switch (err?.networkError?.statusCode) {
        case 403:
          const forbiddenErrMsg = createForbiddenErrorMessage(err);
          authActions.setForbiddenError(forbiddenErrMsg);
          break;
        case 401:
          const unauthErrMsg = createUnauthorizedErrorMessage(err);
          authActions.setUnauthorizedError(unauthErrMsg);
          break;
        default:
          actions.setErrorGeneral(err);
      }
    }

    actions.setIsLoading(false);
  }),

  employmentWasUpdated: false,
  setEmploymentWasUpdated: action((state, payload) => {
    state.employmentWasUpdated = payload;
  }),
  updateEmployment: thunk(async (actions, { applicationId }, helpers) => {
    const { applicationFormService } = helpers.injections;
    const employmentInfo = helpers.getState().payFrequency;
    const authActions = helpers.getStoreActions().auth;

    actions.setEmploymentWasUpdated(false);
    actions.setUpdateEmploymentErrors([]);
    try {
      const response = await applicationFormService.updateEmployment({
        employmentData: { ...employmentInfo, applicationId },
      });

      if (response?.data?.updateEmployment?.updateEmploymentErrors.length > 0) {
        actions.setUpdateEmploymentErrors(response?.data?.updateEmployment.updateEmploymentErrors);
      } else {
        actions.setEmploymentWasUpdated(true);
      }
    } catch (err: any) {
      switch (err?.networkError?.statusCode) {
        case 403:
          const forbiddenErrMsg = createForbiddenErrorMessage(err);
          authActions.setForbiddenError(forbiddenErrMsg);
          break;
        case 401:
          const unauthErrMsg = createUnauthorizedErrorMessage(err);
          authActions.setUnauthorizedError(unauthErrMsg);
          break;
        default:
          actions.setErrorGeneral(err);
      }
    }
  }),
  setUpdateEmploymentErrors: action((state, payload: UpdateEmploymentErrors[]) => {
    state.updateEmploymentErrors = payload;
  }),
  setApplication: action((state, payload: Application) => {
    state.application = payload;
  }),
  setApplyErrors: action((state, payload: SubmitApplicationError[]) => {
    state.applyErrors = payload;
  }),
  setGenerateContractErrors: action((state, payload: GenerateContractErrors[]) => {
    state.generateContractErrors = payload;
  }),
  setLeaseDocuments: action((state, payload: LeaseDocuments) => {
    state.leaseDocuments = payload;
  }),

  filledApplication: computed((state) => {
    const { emailAddress, firstName, lastName } = state.basicInfo;
    const { monthlyIncome, taxpayerIdentificationNumber, birthDate, tokenizedTaxId } = state.basicInfoContinued;

    const filledApplication: FilledApplication = {
      customer: {
        firstName: firstName!,
        lastName: lastName!,
        emailAddress: emailAddress!,
        birthDate: birthDate!,
        cellPhone: state.cellPhone,
        accommodationAddress: state.accommodationAddress,
        billingAddress: state.billingAddress,
      },
      employment: {
        monthlyIncome: monthlyIncome!,
      },
    };

    //we want to delete taxpayerIdentificationNumber when its an empty string so we dont get graphql validation errors. Maybe we should allow both
    //tokenized and untokenized to be sent to backend and we figure out which one to use there
    if (tokenizedTaxId) {
      filledApplication.customer.tokenizedTaxpayerIdentificationNumber = tokenizedTaxId;
    } else if (state.customers && state.customers.length > 0) {
      filledApplication.customer.tokenizedTaxpayerIdentificationNumber = state.customers[0].tokenizedTaxId;
    } else {
      filledApplication.customer.taxpayerIdentificationNumber = taxpayerIdentificationNumber;
    }

    return filledApplication;
  }),

  /**
   * LOADING: GET/SET
   */
  isLoading: false,
  // set isLoading app action
  setIsLoading: action((state, payload) => {
    state.isLoading = payload;
  }),

  /**
   * FORM INPUTS
   */
  setCellPhone: action((state, payload) => {
    state.cellPhone = payload;
  }),

  setBasicInfo: action((state, payload: BasicInfoModel) => {
    state.basicInfo = payload;
  }),

  /**
   * UPDATE BASIC INFO CONTINUED
   */
  basicInfoContinued: {
    birthDate: '',
    // Explicitly set to undefined so that will be removed on apply call for returning customers
    monthlyIncome: undefined,
    taxpayerIdentificationNumber: '',
  },
  setBasicInfoContinued: action((state, payload) => {
    state.basicInfoContinued = payload;
  }),
  updateBasicInfoContinuedErrors: [],
  setUpdateBasicInfoContinuedErrors: action((state, payload) => {
    state.updateBasicInfoContinuedErrors = payload;
  }),
  wasBasicInfoContinuedUpdated: false,
  setWasBasicInfoContinuedUpdated: action((state, payload) => {
    state.wasBasicInfoContinuedUpdated = payload;
  }),
  updateBasicInfoContinued: thunk(async (actions, input: UpdateBasicInfoContinuedInput, helpers: any) => {
    const { applicationFormService } = helpers.injections;
    const authActions = helpers.getStoreActions().auth;
    const orderActions = helpers.getStoreActions().order;

    actions.setWasBasicInfoContinuedUpdated(false);
    actions.setUpdateBasicInfoContinuedErrors([]);
    try {
      const response = await applicationFormService.updateBasicInfoContinued(input);

      if (response?.data?.updateBasicInfoContinued.updateBasicInfoContinuedErrors.length === 0) {
        actions.setApplication(response?.data?.updateBasicInfoContinued?.application);
        orderActions.setApplication(response?.data?.updateBasicInfoContinued?.application);

        actions.setBasicInfoContinued({
          birthDate: input.birthDate ?? '',
          taxpayerIdentificationNumber: input.taxpayerIdentificationNumber ?? '',
          tokenizedTaxId: response?.data?.updateBasicInfoContinued?.application?.customer?.tokenizedTaxId,
          monthlyIncome: input.monthlyIncome ?? '',
        });

        actions.setWasBasicInfoContinuedUpdated(true);
      } else {
        actions.setUpdateBasicInfoContinuedErrors(response?.data?.updateBasicInfoContinued.updateBasicInfoContinuedErrors);
      }
    } catch (err: any) {
      switch (err?.networkError?.statusCode) {
        case 403:
          const forbiddenErrMsg = createForbiddenErrorMessage(err);
          authActions.setForbiddenError(forbiddenErrMsg);
          break;
        case 401:
          const unauthErrMsg = createUnauthorizedErrorMessage(err);
          authActions.setUnauthorizedError(unauthErrMsg);
          break;
        default:
          actions.setErrorGeneral(err);
      }
    }
  }),

  /**
   * UPDATE DEBIT INFO
   */
  debitInfo: {
    cardNumber: '',
    expirationDate: '',
    cardholderFirstName: '',
    cardholderLastName: '',
  },
  setDebitInfo: action((state, payload) => {
    state.debitInfo = payload;
  }),
  wasDebitInfoUpdated: false,
  setWasDebitInfoUpdated: action((state, payload) => {
    state.wasDebitInfoUpdated = payload;
  }),
  setUpdateDebitInfoErrors: action((state, payload) => {
    state.updateDebitInfoErrors = payload;
  }),
  updateDebitInfo: thunk(async (actions, input: UpdateDebitInfoInput, helpers: any) => {
    const { applicationFormService } = helpers.injections;
    const authActions = helpers.getStoreActions().auth;
    const orderState = helpers.getStoreState().order;
    const orderActions = helpers.getStoreActions().order;

    actions.setWasDebitInfoUpdated(false);
    actions.setUpdateDebitInfoErrors([]);
    actions.setDebitInfo(input.debitDetails);
    try {
      const response = await applicationFormService.updateDebitInfo(input);

      await orderActions.queryOrder(orderState.orderId!);

      if (response?.data?.updateDebitInfo.updateDebitInfoErrors.length === 0) {
        // update card from input so it displays on contractSummary after update in OTB flow
        const updatedCard = {
          isExpired: false,
          provider: getCardProvider(input.debitDetails.cardNumber.slice(0, 2)),
          lastFour: input.debitDetails.cardNumber.slice(-4),
        };
        orderActions.setDebitInfo(updatedCard);

        actions.setWasDebitInfoUpdated(true);
      } else {
        actions.setUpdateDebitInfoErrors(response?.data?.updateDebitInfo.updateDebitInfoErrors);
      }
    } catch (err: any) {
      switch (err?.networkError?.statusCode) {
        case 403:
          const forbiddenErrMsg = createForbiddenErrorMessage(err);
          authActions.setForbiddenError(forbiddenErrMsg);
          break;
        case 401:
          const unauthErrMsg = createUnauthorizedErrorMessage(err);
          authActions.setUnauthorizedError(unauthErrMsg);
          break;
        default:
          actions.setErrorGeneral(err);
      }
    }
  }),
  /**
   * AUTHORIZE CARD
   */
  authorizeCardResponse: {
    application: null,
    authorizeCardErrors: [],
  },
  wasCardAuthorized: false,
  setWasCardAuthorized: action((state, payload) => {
    state.wasCardAuthorized = payload;
  }),
  setAuthorizeCardResponse: action((state, payload) => {
    state.authorizeCardResponse = payload;
  }),
  authorizeCard: thunk(async (actions, input: AuthorizeCardInput, helpers: any) => {
    const { applicationFormService } = helpers.injections;
    const authActions = helpers.getStoreActions().auth;
    const orderActions = helpers.getStoreActions().order;

    actions.setWasCardAuthorized(false);
    actions.setAuthorizeCardResponse({
      authorizeCardErrors: [],
    });
    try {
      const response = await applicationFormService.authorizeCard(input);

      if (!!response?.data?.authorizeCard?.application) {
        actions.setApplication(response?.data?.authorizeCard?.application);
        orderActions.setApplication(response?.data?.authorizeCard?.application);
      }

      if (response?.data?.authorizeCard.authorizeCardErrors.length === 0) {
        actions.setWasCardAuthorized(true);
      } else {
        actions.setAuthorizeCardResponse(response?.data?.authorizeCard);
      }
    } catch (err: any) {
      switch (err?.networkError?.statusCode) {
        case 403:
          const forbiddenErrMsg = createForbiddenErrorMessage(err);
          authActions.setForbiddenError(forbiddenErrMsg);
          break;
        case 401:
          const unauthErrMsg = createUnauthorizedErrorMessage(err);
          authActions.setUnauthorizedError(unauthErrMsg);
          break;
        default:
          actions.setErrorGeneral(err);
      }
    }
  }),
  /**
   * REVIEW & SUBMIT
   */
  additionalCustomerInfo: {
    fullTaxId: '',
    fullBankAccount: '',
    cardNumberWithBin: '',
  },
  getAdditionalCustomerInfo: thunk(async (actions, applicationId, helpers) => {
    const { applicationFormService } = helpers.injections;
    const authActions = helpers.getStoreActions().auth;

    return applicationFormService
      .getAdditionalCustomerInfo(applicationId)
      .then((res: ApolloQueryResult<GetAdditionalCustomerInfoQuery>) => {
        actions.setAdditionalCustomerInfo(res.data.getAdditionalCustomerInfo);
      })
      .catch((err: any) => {
        console.error(err);
        if (err?.networkError?.statusCode === 401) {
          const unauthErrMsg = createUnauthorizedErrorMessage(err);
          authActions.setUnauthorizedError(unauthErrMsg);
        } else {
          actions.setErrorGeneral(err);
        }
      });
  }),
  setAdditionalCustomerInfo: action((state, payload) => {
    state.additionalCustomerInfo = payload;
  }),
  /**
   * SELECT LEASE OPTION
   */
  selectLeaseOptionErrors: [],
  selectedLeaseOption: undefined,
  setSelectedLeaseOption: action((state, payload?) => {
    state.selectedLeaseOption = payload;
  }),
  wasLeaseOptionSelected: false,
  setWasLeaseOptionSelected: action((state, payload) => {
    state.wasLeaseOptionSelected = payload;
  }),
  selectLeaseOption: thunk(async (actions, input: any, { injections }) => {
    const { applicationFormService } = injections;

    actions.setSelectLeaseOptionErrors([]);
    actions.setWasLeaseOptionSelected(false);

    try {
      let response = await applicationFormService.selectLeaseOption(input);
      if (response?.data?.selectLeaseOption.selectLeaseOptionErrors.length === 0) {
        actions.setSelectedLeaseOption(input);
        actions.setWasLeaseOptionSelected(true);
      } else {
        actions.setSelectLeaseOptionErrors(response?.data?.selectLeaseOption.selectLeaseOptionErrors);
      }
    } catch (error) {
      actions.setErrorGeneral({ displayText: 'Unable to select lease option' });
      console.error(error);
    }
  }),
  setSelectLeaseOptionErrors: action((state, payload) => {
    state.selectLeaseOptionErrors = payload;
  }),
  /**
   * SIGN LEASE AGREEMENT
   */
  leaseAgreementSigned: false,
  setLeaseAgreementSigned: action((state, payload) => {
    state.leaseAgreementSigned = payload;
  }),
  signLeaseAgreement: thunk(async (actions, input: any, { injections, getStoreActions }) => {
    const { applicationFormService } = injections;
    const authActions = getStoreActions().auth;

    actions.setSignLeaseAgreementErrors([]);

    try {
      let response = await applicationFormService.signLeaseAgreement(input);
      if (response?.data?.signLeaseAgreement.signingErrors.length === 0) {
        actions.setLeaseAgreementSigned(true);
      }

      actions.setSignLeaseAgreementErrors(response?.data?.signLeaseAgreement.signingErrors);
    } catch (err: any) {
      switch (err?.networkError?.statusCode) {
        case 403:
          const forbiddenErrMsg = createForbiddenErrorMessage(err);
          authActions.setForbiddenError(forbiddenErrMsg);
          break;
        case 401:
          const unauthErrMsg = createUnauthorizedErrorMessage(err);
          authActions.setUnauthorizedError(unauthErrMsg);
          break;
        default:
          actions.setErrorGeneral({ displayText: 'Unable to sign lease agreement' });
          console.error(err);
      }
    }
  }),
  setSignLeaseAgreementErrors: action((state, payload) => {
    state.signLeaseAgreementErrors = payload;
  }),
  /**
   * UPDATE SHIPPING ADDRESS
   */
  setUpdateAddressErrors: action((state, payload) => {
    state.updateAddressErrors = payload;
  }),
  setShippingAddressUpdated: action((state, payload?) => {
    state.shippingAddressUpdated = payload;
  }),
  setAccommodationAddress: action((state, payload) => {
    if (payload.line2 === '') {
      delete payload.line2;
    }

    state.accommodationAddress = payload;
  }),
  setBillingAddress: action((state, payload) => {
    if (payload.line2 === '') {
      delete payload.line2;
    }

    state.billingAddress = payload;
  }),

  setShippingAddress: action((state, payload) => {
    if (payload.line2 === '') {
      delete payload.line2;
    }

    state.shippingAddress = payload;
  }),

  bankAccount: undefined,
  setBankAccount: action((state, payload?) => {
    state.bankAccount = payload;
  }),
  updateBankAccount: thunk(async (actions, input: UpdateBankAccountInput, { injections, getStoreActions, getStoreState }) => {
    const { applicationFormService } = injections;
    const authActions = getStoreActions().auth;
    const orderState = getStoreState().order;
    const orderActions = getStoreActions().order;

    actions.setUpdateBankAccountErrors([]);
    actions.setBankAccount(input.bankAccount ?? undefined);

    try {
      const response = await applicationFormService.updateBankAccount(input);

      await orderActions.queryOrder(orderState.orderId!);

      if (response?.data?.updateBankAccount.updateBankAccountErrors.length === 0) {
        actions.setBankAccountUpdated(true);
      } else {
        actions.setUpdateBankAccountErrors(response?.data?.updateBankAccount.updateBankAccountErrors);
      }
    } catch (err: any) {
      switch (err?.networkError?.statusCode) {
        case 403:
          const forbiddenErrMsg = createForbiddenErrorMessage(err);
          authActions.setForbiddenError(forbiddenErrMsg);
          break;
        case 401:
          const unauthErrMsg = createUnauthorizedErrorMessage(err);
          authActions.setUnauthorizedError(unauthErrMsg);
          break;
        default:
          actions.setErrorGeneral(err);
      }
    }
  }),

  updateBankAccountErrors: [],
  setUpdateBankAccountErrors: action((state, payload) => {
    state.updateBankAccountErrors = payload;
  }),

  wasBankAccountUpdated: false,
  setBankAccountUpdated: action((state, payload) => {
    state.wasBankAccountUpdated = payload;
  }),

  shippingAddressUpdated: false,
  updateShippingAddress: thunk(async (actions, input: UpdateShippingAddressInput, { injections, getStoreActions }) => {
    const { applicationFormService } = injections;
    const authActions = getStoreActions().auth;

    //remove line 2 from being sent as an empty string
    if (input.address.line2 === '') {
      delete input.address.line2;
    }

    actions.setShippingAddressUpdated(false);
    actions.setUpdateAddressErrors([]);

    try {
      const response = await applicationFormService.updateShippingAddress(input);
      if (response?.data?.updateShippingAddress) {
        actions.setShippingAddressUpdated(true);
        actions.setShippingAddress(response?.data?.updateShippingAddress.address);
      }
      actions.setUpdateAddressErrors(response?.data?.updateShippingAddress.updateAddressErrors);
    } catch (err: any) {
      switch (err?.networkError?.statusCode) {
        case 403:
          const forbiddenErrMsg = createForbiddenErrorMessage(err);
          authActions.setForbiddenError(forbiddenErrMsg);
          break;
        case 401:
          const unauthErrMsg = createUnauthorizedErrorMessage(err);
          authActions.setUnauthorizedError(unauthErrMsg);
          break;
        default:
          actions.setErrorGeneral(err);
      }
    }
  }),
  setPayFrequency: action((state, payload) => {
    if (payload.nextPayDate === '') {
      delete payload.nextPayDate;
    }
    state.payFrequency = payload;
  }),

  /**
   * FETCH CLIENT IP ADDRESS
   */
  fetchClientIp: thunk(async (actions, _payload = undefined, { injections }) => {
    const { clientIpService } = injections!;

    try {
      const ip = await clientIpService.fetchClientIp();
      if (ip) {
        actions.setClientIp(ip);
      }
    } catch (err) {
      actions.setErrorGeneral(err);
    }
  }),
  /**
   * ELIGIBILITY CHECKS START
   */
  wasEligibilityUpdated: false,
  eligibilityDetails: undefined,
  setEligibilityDetails: action((state, payload: CustomerEligibility) => {
    state.eligibilityDetails = payload;
  }),
  setEligibilityUpdated: action((state, payload: boolean) => {
    state.wasEligibilityUpdated = payload;
  }),
  currentApplyType: computed((state) => {
    return state.eligibilityDetails?.applyTypes[0] ?? null;
  }),
  isCurrentApplyTypeOTB: computed((state) => {
    return state.currentApplyType === ApplyTypes.LeaseOtb || state.currentApplyType === ApplyTypes.LoanOtb;
  }),
  /**
   * ELIGIBILITY CHECKS END
   */
  /**
   * CUSTOMER SERVICE DATA START
   */
  customers: null,
  customerSearchErrors: [],
  customerSearchByCellPhone: thunk(async (actions, payload: CustomerSearchInput, helpers) => {
    actions.setEligibilityUpdated(false);

    const { applicationFormService } = helpers.injections;
    const orderActions = helpers.getStoreActions().order;
    const authActions = helpers.getStoreActions().auth;

    try {
      const { data, errors } = await applicationFormService.customer(payload);

      actions.setCustomers(data.customer.customers);

      // customerFlowType guaranteed to be set after this block
      switch (data.customer.customers.length) {
        case 0:
          actions.setCustomerFlowType(CustomerFlowType.NewCustomer);
          break;
        case 1:
          // there should be only one customer at this point after the search
          // check if customer verified by seeing if PII set in response
          if (data.customer.customers![0].firstName) {
            const onlyCustomer = data.customer.customers![0];

            // only overwrite basic info if not already set (i.e. when customer found via SSN + DoB)
            const oldBasicInfo = helpers.getState().basicInfo;
            if (Object.values(oldBasicInfo).every((val) => !val)) {
              actions.setBasicInfo({
                emailAddress: onlyCustomer?.emailAddress,
                firstName: onlyCustomer?.firstName,
                lastName: onlyCustomer?.lastName,
              });
            }

            // only overwrite basic info cont if not already set (i.e. when customer found via SSN + DoB)
            const oldBasicInfoCont = helpers.getState().basicInfoContinued;
            if (Object.values(oldBasicInfoCont).every((val) => !val)) {
              actions.setBasicInfoContinued({
                taxpayerIdentificationNumber: '',
                birthDate: onlyCustomer.birthDate,
                monthlyIncome: data?.customer?.eligibility?.application?.lease?.employment?.monthlyIncome,
              });
            }

            // only overwrite pay frequency if not already set (i.e. when customer found via SSN + DoB)
            const oldPayFrequency = helpers.getState().payFrequency;
            if (Object.values(oldPayFrequency).every((val) => !val)) {
              actions.setPayFrequency({
                paymentFrequency: data?.customer?.eligibility?.application?.lease?.employment?.payPeriod ?? '',
                lastPayDate: data?.customer?.eligibility?.application?.lease?.employment?.lastPayDate ?? '',
                nextPayDate: data?.customer?.eligibility?.application?.lease?.employment?.nextPayDate ?? '',
                isPaidViaDirectDeposit: data?.customer?.eligibility?.application?.lease?.employment?.isPaidViaDirectDeposit ?? false,
              });
            }

            // only overwrite accommodation address if not already set (i.e. when customer found via SSN + DoB)
            const oldAccommodationAddress = helpers.getState().accommodationAddress;
            if (onlyCustomer.accommodationAddress && Object.values(oldAccommodationAddress).every((val) => !val)) {
              actions.setAccommodationAddress({
                line1: onlyCustomer.accommodationAddress.line1,
                line2: onlyCustomer.accommodationAddress.line2,
                city: onlyCustomer.accommodationAddress.city,
                state: onlyCustomer.accommodationAddress.state,
                zip: onlyCustomer.accommodationAddress.zip,
              });
            }

            // only overwrite billing address if not already set (i.e. when customer found via SSN + DoB)
            const oldBillingddress = helpers.getState().billingAddress;
            if (onlyCustomer.billingAddress && Object.values(oldBillingddress).every((val) => !val)) {
              actions.setBillingAddress({
                line1: onlyCustomer.billingAddress.line1,
                line2: onlyCustomer.billingAddress.line2,
                city: onlyCustomer.billingAddress.city,
                state: onlyCustomer.billingAddress.state,
                zip: onlyCustomer.billingAddress.zip,
              });
            }

            actions.setCustomerFlowType(CustomerFlowType.VerifiedCustomer);

            // these need to default to false to prevent a second get
            const highestSupportedApplicationMilestone = helpers.getStoreState().featureFlags.highestSupportedApplicationMilestone || null;
            const role = helpers.getStoreState().auth.role || null;
            const isVirtualCard = helpers.getStoreState().order.order?.dealer?.isVirtualCard || false;
            const channelType = helpers.getStoreState().order.order?.dealer?.channelType;
            const neuroid_enabled = helpers.getStoreState().featureFlags?.featureFlags?.neuroid_enabled;
            if (shouldProcessNeuroId({ highestSupportedApplicationMilestone, role, isVirtualCard, channelType, neuroid_enabled }))
              nid('setRegisteredUserId', onlyCustomer.id.toString());
          } else {
            // single customer, not verified
            actions.setCustomerFlowType(CustomerFlowType.SingleCustomer);
          }
          break;
        default:
          actions.setCustomerFlowType(CustomerFlowType.MultipleCustomers);
          break;
      }

      actions.setEligibilityDetails(data.customer.eligibility);
      if (data.customer.eligibility) {
        orderActions.setApplication(data.customer.eligibility.application);
      }
      if (errors?.length > 0) {
        // TODO confirm with lauren we never needed to check for network errors here as try/catch should handle
        actions.setResolverErrors(errors);
      }
      actions.setEligibilityUpdated(true);

      actions.setCustomerSearchErrors(data.customer.customerSearchErrors);
    } catch (err: any) {
      switch (err?.networkError?.statusCode) {
        case 403:
          const forbiddenErrMsg = createForbiddenErrorMessage(err);
          authActions.setForbiddenError(forbiddenErrMsg);
          break;
        case 401:
          const unauthErrMsg = createUnauthorizedErrorMessage(err);
          authActions.setUnauthorizedError(unauthErrMsg);
          break;
        default:
          actions.setErrorGeneral(err);
      }
    }
    actions.setIsLoading(false);
  }),
  setCustomerSearchErrors: action((state, payload: CustomerSearchErrors[]) => {
    state.customerSearchErrors = payload;
  }),
  setCustomers: action((state, payload: Customer[]) => {
    state.customers = payload;
  }),
  // Defaulting to undefined to identify we haven't fetched the customers info
  customerFlowType: undefined,
  setCustomerFlowType: action((state, customerFlow) => {
    state.customerFlowType = customerFlow;
  }),
  /**
   * CUSTOMER SERVICE DATA END
   */
  setResolverErrors: action((state, payload: ResolverError[]) => {
    state.resolverErrors = payload;
  }),

  verifyEmail,
  wasFormEdited: false,
  setWasFormEdited: action((state, payload: boolean) => {
    state.wasFormEdited = payload;
  }),
};

export default applicationForm;
