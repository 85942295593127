import React, { useEffect } from 'react';
import PaymentSelectionBase from '../../components/paymentSelectionBase/paymentSelectionBase';
import { useTrack } from '../../hooks/segment';

export enum PaymentSelectionErrors {
  'internetBankError' = 'internetBankError',
  'ineligibleError' = 'ineligibleError',
  'plaidError' = 'plaidError',
}

type PaymentSelectionErrorProps = {
  onPlaidClick: () => void;
  onManualClick: () => void;
  errorType: PaymentSelectionErrors;
  internetBankName?: string;
};

type ErrorDisplayTextType = { [ErrType in PaymentSelectionErrors]: any };

const PaymentSelectionError: React.FC<PaymentSelectionErrorProps> = ({ onPlaidClick, onManualClick, errorType, internetBankName }) => {
  const errorDisplayText: ErrorDisplayTextType = {
    internetBankError: {
      header: 'Internet-only banks aren’t accepted',
      message: `The plaid connection was successful, but we don’t accept internet-only banks like ${internetBankName}. \nPlease connect a checking account at a different bank and try again.`,
      primaryButtonText: 'Connect a different bank',
    },
    ineligibleError: {
      header: 'Bank not eligible',
      message: 'Please connect a checking account at a different bank and try again.',
      primaryButtonText: 'Connect a different bank',
    },
    plaidError: {
      header: "We couldn't connect to your bank",
      message: 'Please try connecting your bank again.',
      primaryButtonText: 'Try again',
    },
  };

  const track = useTrack();

  useEffect(() => {
    track('Payment selection error', { type: errorType });
  }, [track, errorType]);

  return (
    <PaymentSelectionBase
      header={errorDisplayText[errorType].header}
      subText={errorDisplayText[errorType].message}
      primaryButtonText={errorDisplayText[errorType].primaryButtonText}
      primaryButtonAction={onPlaidClick}
      secondaryButtonAction={onManualClick}
    />
  );
};

export default PaymentSelectionError;
