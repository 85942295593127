import React from 'react';
import { IpBehavior } from '../../gql/graphql';
import { selectLeaseHelper } from '../../helpers';
import { convertToCurrency } from '../../helpers/converters/converters';
import { useStoreState } from '../../hooks/easyPeasyHooks';

interface pageTexts {
  bodyText: (string | JSX.Element)[];
  disclosureText: string;
  buttonText: string;
}

// page text logic only
export const usePageText = (): pageTexts => {
  const leaseOption = useStoreState((state) => selectLeaseHelper(state.order.order?.application?.lease?.options));
  const { isLongForm } = useStoreState((state) => state.order);
  const ip = useStoreState((state) => state.order?.order?.application?.lease?.ipUpperBoundary);
  const initialPayment = convertToCurrency(parseFloat(ip!), 0);
  const ipBehavior = useStoreState((state) => state.order?.order?.application?.lease?.ipBehavior);

  if (isLongForm) {
    switch (ipBehavior) {
      case IpBehavior.AuthOnly:
        return {
          bodyText: [
            `To finalize your approval, we need to place a hold of ${initialPayment} on your debit card.`,
            <div style={{ lineHeight: '0.5rem' }}>
              <br />
            </div>,
            `This hold will later be removed and you will not be charged.`,
          ],
          disclosureText: `Wondering about payment terms? You'll be shown all payment details before signing.`,
          buttonText: `Finalize with ${initialPayment} hold`,
        };

      case IpBehavior.VariableDip:
        return {
          bodyText: [
            `To finalize your approval, we need to place a hold of $0.01 on your debit card.`,
            <div style={{ lineHeight: '0.5rem' }}>
              <br />
            </div>,
            `We'll only charge your initial payment of no more than ${initialPayment} plus tax when your order is fulfilled.`,
          ],
          disclosureText: `Wondering about payment terms? You'll be shown all payment details before signing. If you don't sign your lease, the hold will be removed and you will not be charged.`,
          buttonText: `Finalize with $0.01 hold`,
        };

      case IpBehavior.Dip:
        return {
          bodyText: [
            `To finalize your approval, we need to place a hold of $0.01 on your debit card.`,
            <div style={{ lineHeight: '0.5rem' }}>
              <br />
            </div>,
            `We'll only charge your initial payment of ${initialPayment} plus tax when your order is fulfilled.`,
          ],
          disclosureText: `Wondering about payment terms? You'll be shown all payment details before signing. If you don't sign your lease, the hold will be removed and you will not be charged.`,
          buttonText: `Finalize with $0.01 hold`,
        };

      case IpBehavior.Variable:
        return {
          bodyText: [
            `To finalize your approval, we need to place a hold of ${initialPayment} on your debit card.`,
            <div style={{ lineHeight: '0.5rem' }}>
              <br />
            </div>,
            `We'll only charge your initial payment of no more than ${initialPayment} plus tax when your order is fulfilled.`,
          ],
          disclosureText: `Wondering about payment terms? You'll be shown all payment details before signing. If you don't sign your lease, the hold will be removed and you will not be charged.`,
          buttonText: `Finalize with ${initialPayment} hold`,
        };

      case IpBehavior.Traditional:
        return {
          bodyText: [
            `To finalize your approval, we need to place a hold of ${initialPayment} on your debit card.`,
            <div style={{ lineHeight: '0.5rem' }}>
              <br />
            </div>,
            `We'll only charge it (plus tax) as your initial payment if you sign your lease agreement.`,
          ],
          disclosureText: `Wondering about payment terms? You'll be shown all payment details before signing. If you don't sign your lease, the hold will be removed and you will not be charged.`,
          buttonText: `Finalize with ${initialPayment} hold`,
        };
    }
  }

  // short form and default
  return {
    bodyText: [
      `We'll place a hold of ${convertToCurrency(leaseOption?.applicationFee ?? 0)} on your debit card to finalize your approval amount.`,
      <div style={{ lineHeight: '0.5rem' }}>
        <br />
      </div>,
      `We'll only charge it (plus tax) if you sign your lease agreement.`,
    ],
    disclosureText: `Wondering about payment terms? You'll be shown all payment details before signing, and if you don't sign your lease, the authorization hold will be removed and you will not be charged.`,
    buttonText: `Finalize Approval`,
  };
};
