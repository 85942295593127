import React from 'react';
import styled from '@emotion/styled';

const FineTextStyle = styled.div`
  p {
    font-size: 0.65rem;
  }
`;

const FineText: React.FC = () => {
  return (
    <FineTextStyle>
      <p>Additional fees including returned payment fee, early purchase fee, and debit transaction fee may apply, as allowed by law.</p>
      <p>
        Your Koalafi lease has an initial term of less than 3 months and the term renews with each scheduled payment. You can contact Koalafi at 844.937.8275
        anytime and voluntarily return or surrender the leased property in good repair, ordinary wear and tear expected, following the directions. Please see
        your agreement for details. Koalafi does not charge voluntary return fees if you follow our return directions.
      </p>
      <p>I acknowledge payment rights and obligations will be described in the Payment Method section in the lease agreement.</p>
    </FineTextStyle>
  );
};

export default FineText;
