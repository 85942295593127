import { Role } from '../cookies/cookie.helper';

const ORG_ID = process.env['REACT_APP_THREAT_METRIX_ORG_ID'];

const conditionallySetupTMXSession = (checkoutId: string, role: Role | null) => {
  // ONLY SETUP TMX SESSION FOR CUSTOMERS
  if (role !== Role.Customer) {
    console.log('TMX session not setup for non customer roles');
    return;
  }

  const script = document.createElement('script');

  script.src = `https://content.koalafi.com/fp/tags.js?org_id=${ORG_ID}&session_id=${checkoutId}`;
  script.defer = true;

  document.body.appendChild(script);
};

export { conditionallySetupTMXSession };
