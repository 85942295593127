import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useStoreState } from '../../hooks/easyPeasyHooks';
import { useTrack, usePage } from '../../hooks/segment';
import { selectLeaseHelper } from '../../helpers/leases/leases.helpers';
import { ReactComponent as WallySunglassesImage } from '../../assets/Wally_Sunglasses.svg';
import { ReactComponent as WallyHappy } from '../../assets/wally_happy.svg';
import { ReactComponent as Confetti } from '../../assets/confetti.svg';
import { Role } from '../../helpers/cookies/cookie.helper';
import { convertToCurrency } from '../../helpers/converters/converters';
import { AppConfig } from '../../app.config';
import LeaseTermsTooltip from '../../components/lease-terms-tooltip/lease-terms-tooltip';
import { FormNavButtonWrapper } from '../../components/formElements/FormElements';
import Button from '../../components/button/Button';
import { colors, fontFamilies } from '../../components/baseStyle/Variables';

type Props = {
  ecommApprovalOnly: boolean;
};

const ApprovalSubText = styled.div`
  color: ${colors.darkBlue};
  font-family: ${fontFamilies.interLight};
  font-size: 1.1rem;
  text-align: left;
  margin-top: 1rem;
`;

const CurrencyStyle = styled.div`
  font-family: ${fontFamilies.interBold};
  line-height: 1.4;
  font-size: 1.75rem;
`;

const Gradient = styled.div`
  width: 100%;
  max-width: 465px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0) 100%);
  margin-top: 1.5rem;
  height: 50px;
`;

const WallyImgStyle = styled.div`
  position: absolute;
  width: 5rem;
  right: 1rem;
  bottom: -2.4rem;
  z-index: 1; // without this, the gradient overlaps wally's feet
`;
const ConfettiStyle = styled.div<{ prequal?: boolean }>`
  position: absolute;
  width: 4rem;
  right: 2rem;
  bottom: 100%;
`;

const getWallyImg = (isOtb: boolean) =>
  isOtb ? (
    <WallyImgStyle>
      <WallySunglassesImage />
    </WallyImgStyle>
  ) : (
    <>
      <ConfettiStyle>
        <Confetti />
      </ConfettiStyle>
      <WallyImgStyle>
        <WallyHappy />
      </WallyImgStyle>
    </>
  );

const ApprovalConfirmation: React.FC<Props> = (props: Props) => {
  const { order, orderId } = useStoreState((state) => state.order);
  const dealerId = useStoreState((state) => state.order.order?.dealer.id);
  const { eligibilityDetails, isCurrentApplyTypeOTB } = useStoreState((state) => state.applicationForm);
  const applicationNumber = order?.application?.lease?.displayId;
  const { role } = useStoreState((state) => state.auth);
  const track = useTrack();
  const page = usePage();

  const isOtb = isCurrentApplyTypeOTB || !!order?.application?.lease?.isOtb || !!order?.application?.loan?.isOtb;

  useEffect(() => {
    page('Approval Confirmation', {
      orderId,
      dealerId,
      approvedAmount: selectLeaseHelper(eligibilityDetails?.otbProducts?.leaseOptions)?.approvedAmount,
    });
    if (isOtb) {
      track('OTB Lease Approval Confirmed');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  type PageText = {
    buttonText: string | null;
    pageText: JSX.Element | null;
  };

  const getContinueText = () => {
    if (!!props.ecommApprovalOnly) {
      return <>Continue shopping at {order?.dealer.friendlyName} and use Koalafi as your payment option at checkout.</>;
    } else {
      return (
        <>
          Talk to your sales associate to begin checkout and complete your purchase. Your application number is <strong>{applicationNumber}</strong>
        </>
      );
    }
  };

  const getScreenText = (): PageText => {
    if (role === Role.Customer) {
      return {
        buttonText: null,
        pageText: <h6>{getContinueText()}</h6>,
      };
    } else {
      return {
        buttonText: 'Open application',
        pageText: <h6>Open the application to finalize the lease and send your customer their agreement.</h6>,
      };
    }
  };

  const handleSubmit = () => {
    window.location.href = `${AppConfig.dealerPortalUrl}/PurchasedArticlesForm/Index?publicId=${order?.application?.lease?.publicId}`;
  };

  const leaseOptions = eligibilityDetails?.otbProducts?.leaseOptions?.length
    ? eligibilityDetails?.otbProducts?.leaseOptions
    : order?.application?.lease?.options;

  return (
    <div>
      <h1 style={{ fontSize: '3.5rem' }}>{isOtb ? 'Approval Confirmed' : "You're approved"}</h1>
      <div style={{ display: 'flex', position: 'relative' }}>
        {getWallyImg(isOtb)}
        <ApprovalSubText>
          <React.Fragment>
            <div>
              for our <LeaseTermsTooltip styling={{ fontSize: '1.1rem' }} />
            </div>
            <div>financing up to</div>
          </React.Fragment>
          <CurrencyStyle>
            {isOtb
              ? convertToCurrency(parseFloat(selectLeaseHelper(leaseOptions)?.approvedAmount!)) // TODO: validation?
              : convertToCurrency(parseFloat(selectLeaseHelper(leaseOptions)?.approvedAmount!), 0)}
          </CurrencyStyle>
        </ApprovalSubText>
      </div>
      <Gradient />
      <div style={{ marginTop: '3rem' }}>
        {getScreenText().pageText}

        <div style={{ marginTop: '2rem' }}>
          {!!getScreenText().buttonText && (
            <FormNavButtonWrapper>
              <Button onClick={handleSubmit}>{getScreenText().buttonText}</Button>
            </FormNavButtonWrapper>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApprovalConfirmation;
