import styled from '@emotion/styled';
import React from 'react';
import { colors, fontFamilies } from '../baseStyle/Variables';
import Button from '../button/Button';
import Loader from '../loader/Loader';

type Props = {
  heading: string;
  body: string | JSX.Element;
  additionalBody?: string;
  buttonText?: string;
  buttonClick?: any;
  shouldShowButton?: boolean;
  lowerHeading?: string;
  loading?: boolean;
};

const Container = styled.div`
  text-align: left;
  position: relative;
`;

const HeadingWrap = styled.div`
  margin-bottom: 1rem;
`;

// TODO Move the button style to Chimera
const ButtonWrap = styled.div`
  margin: 50px 0;

  & button {
    font-family: ${fontFamilies.gilroySemiBold};
    font-size: 1.3rem;
    font-weight: normal;
    margin-top: 0.5rem;
    height: 58px;
  }
`;
const Message: React.FC<Props> = ({ heading, body, additionalBody, buttonText, buttonClick, shouldShowButton = true, lowerHeading, loading }: Props) => {
  return (
    <Container>
      <HeadingWrap>
        <h2>{heading}</h2>
      </HeadingWrap>
      <p data-testid="message-body" style={{ color: colors.darkBlue, fontSize: '20px', lineHeight: '24px' }}>
        {body}
      </p>
      {additionalBody && <p style={{ color: colors.darkBlue, fontSize: '20px', lineHeight: '24px' }}> {additionalBody}</p>}
      {lowerHeading && <h6>{lowerHeading}</h6>}
      {loading && <Loader source="message" />}
      {buttonText && buttonClick && shouldShowButton && (
        <ButtonWrap>
          <Button onClick={buttonClick}>{buttonText}</Button>
        </ButtonWrap>
      )}
    </Container>
  );
};

export default Message;
