import { createMachine, sendParent } from 'xstate';
import { CustomerFlowType } from '../stores/applicationForm.store';
import { OtbEvents, OtbMachineContext, OtbMachineEvents, OtbMachineStates, OtbStates } from './types/otbMachine.types';
import { shouldTransferToLoans, transferToLoansUi, transferToRapid } from '../helpers/cookies/unified.helper';
import { EcommMachineEvents } from './types/ecommMachine.types';
import { ChannelTypes, FormTypes } from '../gql/graphql';

/** @xstate-layout N4IgpgJg5mDOIC5QHkAqAhAwgV1gFwHsBbMAJwDoBRAOz1IE8BiNdVegBzAG0AGAXUSh2BWAEs8ogtUEgAHogCMAFgCc5AMwA2dQCYAHOqUB2PZr08lAGhD1ESnevIqArEqU89Ko5qWaVmgF8A6xYcfGIyKloGZgw2Ti4FASQQYTEJKRl5BGU1LV0DY1NzKxtEHVUNIwUPJVNtFXV1IJCMMMISCgBBdnZSAgA3AEMAG1jWDm5+GTTxSWkU7OdXch1nFQUFVxUeZz0FTWtbBDWdciMdHgseHQPjIxUWkFDcDsievsHR8fjuJJmRHNMotEP4zs4Hm5Gro1nodEc7MoNLobjxNDwFLCni9wp1yAA5AhdADGxII2FoADFydQIIx8WBZHheMkhICMgtQNkweQISooU0dLD4WUEPtyHVnJpqkopXVNELsW1XhEKAAZIb4anYUiMACS1DJpFIYGJeAAyub8SyAel5llEM59OQFOpoepvDoTCLjuLJdLlHLTIrgs9lbjIhqteTdSxfjaUrMOQ6EE69C63U0PQrvQiTjdVuoLJoakp1FsMQolVgVXio3htbG4pNEqzUuz7SDU87Xe7PbnRUXHJd-ApGp5zBCgqHqAQIHAZDi3qRbUDOXJEABaQ6i7fkK5XHQqFyY-TqPTOavtVVROjHNl24Fcx1GF2qIz2GofoulY5KBTpjwbqyhsvj9letaRAAIqaIyiNQYCrsmXb2Hm6jrLyqgXoeQpokoEERhQlCyKI+DwVAHxwcSQwcpSIwEAA7khnbPjkI4unoRhOh+1QHAoebuK+eweh+TpohsVahkuN4fP0wwjMxT4bggDzpnUZY7MYCrygJSIGJcTRlh+KicZeUnhsuBJEqSNINjSECKeu2QmEo5zGP+Y4YmYdR5uKJlSno9gqF6XoEZZ9aNo5KZus4TjVFofFcQYChGHmFRqJxNReEYNzaP+zTmTWhEEmADGUaI1HIQ+a4ppiPBqABXFesYmwlr5ChOBe3lHiFfj4dOQA */
export default createMachine<OtbMachineContext, OtbMachineEvents, OtbMachineStates>(
  {
    id: 'OTBCustomer',
    initial: OtbStates.LastFour,
    context: {
      order: null,
      customerFlowType: null,
      isRedirecting: false,
      ecommApprovalOnly: false,
    },
    states: {
      [OtbStates.LastFour]: {
        on: {
          [OtbEvents.NotRecognized]: [
            { cond: 'IsRedirecting' }, // used to stop checking transitions while redirecting
            { actions: 'transferToLoansUi', cond: 'ShouldTransferToLoans' },
            { target: 'NewApplication', cond: 'IsLongApp' }, // starting a new long form app
            { target: 'BasicInfo' },
          ],
          [OtbEvents.ApplicationStatus]: [
            { cond: 'IsRedirecting' }, // used to stop checking transitions while redirecting
            { actions: 'transferToLoansUi', cond: 'ShouldTransferToLoans' },
            { target: 'ExistingApplication', cond: 'IsVirtualCard' }, // don't need to show approval here since virtual card flow does
            { target: 'Approval', cond: 'IsBrickAndMortar' },
            { target: 'Approval' },
          ],
          [OtbEvents.CustomerIdentificationButton]: {
            target: 'CustomerIdentification',
          },
          [OtbEvents.TermsAndConditionsButton]: {
            target: 'TermsAndConditions',
          },
          [OtbEvents.AuthorizedUserTermsButton]: {
            target: 'AuthorizedUserTerms',
          },
        },
      },
      [OtbStates.Approval]: {
        on: {
          [OtbEvents.NEXT]: [
            { cond: 'IsRedirecting' }, // used to stop checking transitions while redirecting
            { actions: 'transferToLoansUi', cond: 'ShouldTransferToLoans' },
            { target: 'ApprovalConfirmation', cond: 'IsBrickAndMortar' },
            { target: 'ExistingApplication' },
          ],
        },
      },
      [OtbStates.BasicInfo]: {
        on: {
          [OtbEvents.NEXT]: {
            target: 'BasicsContinued',
          },
        },
      },
      [OtbStates.BasicsContinued]: {
        on: {
          [OtbEvents.NEXT]: [
            {
              target: 'LastFour',
              cond: (_context, event) => !!event.data?.isCurrentApplyTypeOTB,
            },
            {
              target: 'NewApplication',
            },
          ],
          [OtbEvents.BACK]: {
            target: 'BasicInfo',
          },
        },
      },
      [OtbStates.NewApplication]: {
        entry: [
          sendParent({
            type: EcommMachineEvents.NEW_APPLICATION,
          }),
        ],
        // invoke: {
        //   src: NewApplicationMachine,
        // },
      },
      [OtbStates.ExistingApplication]: {
        type: 'final',
        entry: [
          sendParent((context, event) => ({
            type: EcommMachineEvents.EXISTING_APPLICATION,
            data: {
              appMode: event?.data?.appMode,
              order: event?.data?.order,
              skipApproval: true,
            },
          })),
        ],
      },
      [OtbStates.VirtualCard]: {
        type: 'final',
        entry: [
          sendParent((context, event) => ({
            ...context,
            type: EcommMachineEvents.VIRTUAL_CARD_APP,
          })),
        ],
      },
      [OtbStates.CustomerIdentification]: {
        on: {
          [OtbEvents.NEXT]: {
            target: 'LastFour',
          },
        },
      },
      [OtbStates.TermsAndConditions]: {
        on: {
          [OtbEvents.NEXT]: {
            target: 'LastFour',
          },
        },
      },
      [OtbStates.AuthorizedUserTerms]: {
        on: {
          [OtbEvents.NEXT]: {
            target: 'LastFour',
          },
        },
      },
      [OtbStates.ApprovalConfirmation]: {},
    },
    schema: {
      context: {} as OtbMachineContext,
      events: {} as OtbMachineEvents,
    },
    predictableActionArguments: true,
    preserveActionOrder: true,
  },
  {
    actions: {
      transferToLoansUi: (context, event) => {
        context.isRedirecting = true;
        transferToLoansUi();
      },
      transferToRapid: (context, event) => {
        context.isRedirecting = true;
        transferToRapid();
      },
    },
    guards: {
      Single: (context, event) => context.customerFlowType === CustomerFlowType.SingleCustomer,
      Multi: (context, event) => {
        return context.customerFlowType === CustomerFlowType.MultipleCustomers;
      },
      ShouldTransferToLoans: (context, event) => {
        // this doesnt check current state, so ordering really matters
        return !context.isRedirecting && shouldTransferToLoans();
      },
      IsBrickAndMortar: (context, event) => {
        return context.order?.dealer?.channelType === ChannelTypes.BrickAndMortar;
      },
      IsRedirecting: (context, event) => {
        return context.isRedirecting;
      },
      IsVirtualCard: (context, event) => {
        return !!context.order?.dealer?.isVirtualCard;
      },
      IsLongApp: (context, event) => context.order?.dealer?.formType === FormTypes.Long || context.order?.dealer?.formType === FormTypes.LongV2,
    },
  },
);

// couldn't confirm IDV -> new customer
// confirmed IDV, ineligible -> inelig screen
// confirmed IDV, eligible non OTB -> prefilled new app screen
// confirmed IDV, OTB -> approved screen (then shipping after that, so approved screen has another onNext() case)
