import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as KoalafiLogoImg } from '../../assets/koalafi-logo.svg';

import { ReactComponent as KoalafiHPLogo } from '../../assets/koalafi-hp.svg';
import { isStoreHP } from '../../helpers/merchants/merchants';

type Props = { dealerId?: number };

const KoalafiLogo: React.FC<Props> = ({ dealerId }) => {
  const StyledKoalafiLogo = styled.div`
    display: flex;
    flex-grow: 5;
    height: 2rem;
    justify-content: center;
    align-items: center;
  `;

  return <StyledKoalafiLogo>{isStoreHP(dealerId) ? <KoalafiHPLogo title={'Koalafi'} /> : <KoalafiLogoImg title={'Koalafi'} />}</StyledKoalafiLogo>;
};

export default React.memo(KoalafiLogo);
