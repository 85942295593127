import { action, Action, Thunk, thunk } from 'easy-peasy';
import { ErrorMessage } from './applicationForm.store';
import { HighestSupportedApplicationMilestoneQuery } from '../gql/graphql';
import { ApolloQueryResult } from '@apollo/client';

export interface FeatureFlagModel {
  error?: ErrorMessage | null;
  setErrorGeneral: Action<FeatureFlagModel, ErrorMessage | undefined>;
  featureFlags?: Features;
  queryFeatureFlags: Thunk<FeatureFlagModel>;
  setFeatureFlags: Action<FeatureFlagModel, Features>;

  highestSupportedApplicationMilestone: number | null | undefined;
  setHighestSupportedApplicationMilestone: Action<FeatureFlagModel, number | null | undefined>;
  queryHighestSupportedApplicationMilestone: Thunk<FeatureFlagModel, number>;
}

export interface Features {
  otb_enabled: boolean;
  neuroid_enabled: boolean;
  plaid_enabled: boolean;
  plaid_a_b_testing: boolean;
  plaid_a_b_testing_lle: boolean;
}

const featureFlag: FeatureFlagModel = {
  error: null,
  setErrorGeneral: action((state, payload?: ErrorMessage) => {
    state.error = payload;
  }),
  setFeatureFlags: action((state, payload: Features) => {
    state.featureFlags = payload;
  }),
  queryFeatureFlags: thunk(async (actions, payload = undefined, { injections }) => {
    const { featureFlagService } = injections!;
    try {
      const result = await featureFlagService.getFeatureFlags();
      const payload = result.data.featureFlags.reduce(
        (result: any, next: { name: any; enabled: any }) => ({
          ...result,
          [next.name]: next.enabled,
        }),
        {},
      );
      // TODO - REMOVE ONCE OTB FLAG IS MOCKED LOCALLY
      // console.log('ADJUST FLAGS');
      payload.otb_enabled = true;
      // console.log('OTB ENABLED: ' + payload.otb_enabled);
      actions.setFeatureFlags(payload);
    } catch (err) {
      const errorMessage: ErrorMessage = {
        stackTrace: err,
        displayText: 'Unable to fetch feature flags',
      };
      actions.setErrorGeneral(errorMessage);
    }
  }),

  highestSupportedApplicationMilestone: null,
  setHighestSupportedApplicationMilestone: action((state, payload?) => {
    state.highestSupportedApplicationMilestone = payload;
  }),
  queryHighestSupportedApplicationMilestone: thunk(async (actions, dealerId, { injections }) => {
    const { featureFlagService } = injections;
    try {
      featureFlagService
        .highestSupportedApplicationMilestone(dealerId)
        .then((res: ApolloQueryResult<HighestSupportedApplicationMilestoneQuery>) => {
          actions.setHighestSupportedApplicationMilestone(res.data.highestSupportedApplicationMilestone);
        })
        .catch((err: any) => {
          console.error(err);
          actions.setErrorGeneral(err);
        });
    } catch (err) {
      const errorMessage: ErrorMessage = {
        stackTrace: err,
        displayText: 'Unable to fetch highest supported application milestone',
      };
      actions.setErrorGeneral(errorMessage);
    }
  }),
};

export default featureFlag;
