import { AccommodationProps } from '../../pages/accommodationAddress/AccommodationAddress';
import { ApprovalProps } from '../../pages/approval/Approval';
import { OTPRequestProps } from '../../pages/otpRequest/OTPRequest';

export const pageContentConfig: { [index: string]: any } = {
  full_spectrum: {
    billing: {
      title: 'Where do you live?',
      submitButton: 'Check eligibility',
    },
    approval: {
      approvedText: "you're prequalified for a ",
    },
    otpRequest: {
      creditScoreText: 'No impact to your credit score',
    },
  },
  lto_only: {
    billing: {
      title: 'Where do you live?',
      submitButton: 'Check eligibility',
    },
    approval: {
      approvedText: "you're prequalified for our ",
    },
    otpRequest: {
      creditScoreText: 'No impact to your credit score',
    },
  },
  loan_only: {
    billing: {
      title: 'Where do you live?',
      subTitle: 'Enter your billing address',
      submitButton: 'Check eligibility',
    },
    approval: {
      approvedText: "you're prequalified for a ",
    },
    otpRequest: {
      creditScoreText: 'No impact to your credit score',
    },
  },
};

//used for the shared pages of leases and loans
export interface SharedPages {
  billing: AccommodationProps;
  approval: ApprovalProps;
  otpRequest: OTPRequestProps;
}
