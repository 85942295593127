import React, { ReactNode } from 'react';
import { Text, Spacer } from '@koalafi/component-library';
import Button from '../button/Button';

type PaymentSelectionBaseProps = {
  header: ReactNode;
  subText: ReactNode;
  primaryButtonText: string;
  primaryButtonAction: () => void;
  secondaryButtonAction: () => void;
  isLoading?: boolean;
};

const PaymentSelectionBase: React.FC<PaymentSelectionBaseProps> = ({
  header,
  subText,
  primaryButtonText,
  primaryButtonAction,
  secondaryButtonAction,
  isLoading = false,
}) => {
  const plaidLoading = false;

  const secondaryButtonClasses =
    'text-accents-primary hover:bg-surface-secondary-container disabled:bg-transparent disabled:text-on-disabled disabled:opacity-60 p-[1rem 0]';
  const buttonBorder = 'w-full p-sm border-2 disabled:border-on-disabled border-accents-primary';
  const buttonTextClass = 'font-[Inter-SemiBold] text-[1rem] justify-center items-center cursor-pointer';
  const combinedStyles = `${secondaryButtonClasses} ${buttonBorder} ${buttonTextClass}`;

  return (
    <div>
      {/* Header */}
      <Text size="3xl" alignText="left" weight="extrabold">
        {header}
      </Text>
      <Spacer size="md" />
      <Text size="md" alignText="left" weight="light">
        {subText}
      </Text>
      <Spacer size="lg" />
      <Button type="submit" disabled={plaidLoading} onClick={primaryButtonAction} dataLoading={isLoading}>
        {primaryButtonText}
      </Button>
      <Spacer size="sm" />
      <button
        style={{ borderRadius: '0.625rem', fontFamily: 'Inter-SemiBold', fontSize: '1rem' }}
        className={combinedStyles}
        type="submit"
        onClick={secondaryButtonAction}
        data-testid="payment-selection-manual"
      >
        Enter bank info manually
      </button>
    </div>
  );
};

export default PaymentSelectionBase;
