import React, { Suspense, useEffect } from 'react';
import EntryPointFlow from './EntryPointFlow';
import { lazyWithPreload } from 'react-lazy-with-preload';
import {} from '../basicInfoContinued/BasicInfoContinued';
import Loader from '../../components/loader/Loader';
import Declined from '../declined/Declined';

// TODO: add a description for why this is done this way
const CustomerLookupFlow = lazyWithPreload(() => import('./CustomerLookupFlow'));
const ExistingApplicationFlow = lazyWithPreload(() => import('./ExistingApplicationFlow'));
const NewApplicationFlow = lazyWithPreload(() => import('./NewApplicationFlow'));
const OtbFlow = lazyWithPreload(() => import('./OTBFLow'));
const DraftFlow = lazyWithPreload(() => import('./LongFormFlow'));

// @ts-nocheck
type FlowContainerProps = {
  send: any;
  setHeaderGoBackFunc: React.Dispatch<React.SetStateAction<(() => void) | undefined>>;
  setCurrentNode: (state: [string] | undefined) => void;
  machine: any;
};
const FlowContainer = ({ setHeaderGoBackFunc, setCurrentNode, machine }: FlowContainerProps) => {
  useEffect(() => {
    CustomerLookupFlow.preload();
    ExistingApplicationFlow.preload();
    NewApplicationFlow.preload();
    OtbFlow.preload();
    DraftFlow.preload();
  }, []);

  const [state] = machine;
  switch (true) {
    case state.matches('EntryPoint'):
      return <EntryPointFlow parentMachine={machine} setHeaderGoBackFunc={setHeaderGoBackFunc} setCurrentNode={setCurrentNode} />;
    case state.matches('CustomerLookupFlow'): // TODO: use enum consts
      return (
        <Suspense fallback={<Loader source="customer-lookup-flow" />}>
          <CustomerLookupFlow parentMachine={machine} setHeaderGoBackFunc={setHeaderGoBackFunc} setCurrentNode={setCurrentNode} />
        </Suspense>
      );
    case state.matches('NewApplication'):
      return (
        <Suspense fallback={<Loader source="new-application-flow" />}>
          <NewApplicationFlow parentMachine={machine} setHeaderGoBackFunc={setHeaderGoBackFunc} setCurrentNode={setCurrentNode} />
        </Suspense>
      );
    case state.matches('ExistingApplication'):
      return (
        <Suspense fallback={<Loader source="existing-application-flow" />}>
          <ExistingApplicationFlow parentMachine={machine} setHeaderGoBackFunc={setHeaderGoBackFunc} setCurrentNode={setCurrentNode} />
        </Suspense>
      );
    case state.matches('OTB'):
      return (
        <Suspense fallback={<Loader source="otb-flow" />}>
          <OtbFlow parentMachine={machine} setHeaderGoBackFunc={setHeaderGoBackFunc} setCurrentNode={setCurrentNode} />
        </Suspense>
      );
    case state.matches('LongFormApplication'):
      return (
        <Suspense fallback={<Loader source="draft-flow" />}>
          <DraftFlow parentMachine={machine} setHeaderGoBackFunc={setHeaderGoBackFunc} setCurrentNode={setCurrentNode} />
        </Suspense>
      );
    case state.matches('LeaseDeclined'):
      return <Declined trackDetails={{ title: 'Application Declined' }} />;
    case state.matches('LoanDeclined'):
      return <Declined trackDetails={{ title: 'Loan Denied' }} />;
    default:
      return null;
  }
};

export default FlowContainer;
