import { EventObject, Typestate } from 'xstate';
import { Order } from '../../gql/graphql';

// Context Types
export type EntryPointMachineContext = {
  order: Order | null;
  page: string;
  ecommApprovalOnly?: boolean;
};

// State Types
export enum EntryPointStates {
  ApprovalConfirmation = 'ApprovalConfirmation',
  Initial = 'EntryPointInitial',
  OTPVerification = 'OTPVerification',
  OTPRequest = 'OTPRequest',
  WelcomeBack = 'WelcomeBack',
  PreApproval = 'PreApproval',
  ContractSummary = 'ContractSummary',
  BankAccountError = 'BankAccountError',
  DebitCardError = 'DebitCardError',
  LoanDeclined = 'LoanDeclined',
  LeaseDeclined = 'LeaseDeclined',
  Done = 'Done',
  DraftAppReviewAndSubmit = 'DraftAppReviewAndSubmit',
}

export interface EntryPointMachineStatesSchema {
  [EntryPointStates.ApprovalConfirmation]: {};
  [EntryPointStates.OTPVerification]: {};
  [EntryPointStates.OTPRequest]: {};
  [EntryPointStates.WelcomeBack]: {};
  [EntryPointStates.PreApproval]: {};
  [EntryPointStates.ContractSummary]: {};
  [EntryPointStates.LeaseDeclined]: {};
  [EntryPointStates.LoanDeclined]: {};
  [EntryPointStates.Done]: {};
  [EntryPointStates.DraftAppReviewAndSubmit]: {};
}

export interface EntryPointMachineStates extends Typestate<EntryPointMachineContext> {
  type: EntryPointMachineStatesSchema;
}

// Event Types
export enum EntryPointEvents {
  NEXT = 'NEXT',
  BACK = 'BACK',
  EDIT_DEBIT_INFO = 'EDIT_DEBIT_INFO',
  APPROVED = 'Approved',
  DECLINED_OR_ERROR = 'DeclinedOrError',
  COMPLETE_APP_LATER = 'CompleteAppLater',
}

type EntryPointEventsTypeSchema =
  | EntryPointEvents.NEXT
  | EntryPointEvents.BACK
  | EntryPointEvents.EDIT_DEBIT_INFO
  | EntryPointEvents.APPROVED
  | EntryPointEvents.DECLINED_OR_ERROR
  | EntryPointEvents.COMPLETE_APP_LATER;

export interface EntryPointMachineEvents extends EventObject {
  type: EntryPointEventsTypeSchema;
  data?: any;
}
